import axios from "axios";

// const apiURL = process.env.REACT_APP_BASE_URL;
const apiURL = process.env.REACT_APP_BACKEND_URL;

// get data by id, tag ==> source
const getDataRequest = (path, source, role, page, search) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let url = `${apiURL}/${path}/${source}?role=${role}&page=${page}`;
  if (search) {
    url += `&search=${search}`;
  }
  return axios.get(url, config);
};

// get data by id, tag ==> source
const getDataByTag = async (source, path, role) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  let url = `${apiURL}/${path}/${source}`;
  if (role !== undefined) {
    url += `?role=${role}`;
  }

  let res = await axios.get(url, config);
  return res;
};

// get magazine data
const getMagazineDataRequest = (tag) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/allMagazines`, config);
};

// get special profile data
const getSpecialProfileData = (role, page) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(
    `${apiURL}/allSpecialProfiles?role=${role}&page=${page}`,
    config
  );
};

// standered get api call
const getApiRequest = async (path, role, page, search) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let url = `${apiURL}/${path}`;
  if (search) {
    url += `?search=${search}`;
    if (role !== undefined && page !== undefined) {
      url += `&role=${role}&page=${page}`;
    }
  } else {
    if (role !== undefined && page !== undefined) {
      url += `?role=${role}&page=${page}`;
    }
  }

  return axios.get(url, config);
};

// login
const login = (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/login`, data, config);
};

// get up trending data
const getUpTrendingRequest = (role, page) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  let url = `${apiURL}/allUpTrendings`;
  if (role !== undefined && page !== undefined) {
    url += `?role=${role}&page=${page}`;
  }

  return axios.get(url, config);
};

// get all dailyScoop data
const getAllDailyScoopRequest = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/allDailyScoop`, config);
};

// get dailyScoop data
const getDailyScoopRequest = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/dailyScoop`, config);
};

// get dailyScoop data by id
const getDailyScoopRequestbyid = (id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/dailyScoop/${id}`, config);
};

// get dailyScoop data by tag
const getDailyScoopByTagRequest = (tag) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.get(`${apiURL}/dailyScoops/${tag}`, config);
};

// update data by ID
const updateDataByID = async (path, data, id) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: token,
    },
  };

  return axios.put(`${apiURL}/${path}/${id}`, data, config);
};

// Post data
const addDataRequest = (data) => {
  let token = localStorage.getItem("auth-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  let result = axios.post(`${apiURL}/addDailyScoop`, data, config);
  return result;
};

// Add up trending data
const addUpTrendingDataRequest = (data) => {
  let token = localStorage.getItem("auth-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  let result = axios.post(`${apiURL}/addUpTrending`, data, config);
  return result;
};

//get uptrending by id
const getUpTrendingById = async (id, role, page) => {
  let token = localStorage.getItem("auth-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.get(
    `${apiURL}/upTrending/${id}?role=${role}&page=${page}`,
    config
  );
};

//get uptrending by id
const getSpecialById = async (id, role) => {
  let token = localStorage.getItem("auth-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.get(`${apiURL}/specialProfile/${id}?role=${role}`, config);
};

// put data
const putDataRequest = (data) => {
  let accessTokan = "Test";
  return axios.put(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

//get article by redirecturl
const getArticleByRedirectURL = (redirectURL, role) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/${redirectURL}?role=${role}`, config);
};

// delete data
const deleteDataRequest = (id) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  };

  return axios.delete(`${apiURL}/dailyScoop/${id}`, config);
};

export const commonApi = {
  getApiRequest,
  updateDataByID,
  getDataRequest,
  getDailyScoopRequest,
  getAllDailyScoopRequest,
  addDataRequest,
  putDataRequest,
  deleteDataRequest,
  login,
  getDailyScoopByTagRequest,
  getUpTrendingRequest,
  addUpTrendingDataRequest,
  getDailyScoopRequestbyid,
  getMagazineDataRequest,
  getDataByTag,
  getUpTrendingById,
  getSpecialProfileData,
  getSpecialById,
  getArticleByRedirectURL,
};
