export const dashboardConstants = {
  CONTENT_CREATION_FAILURE: "CONTENT_CREATION_FAILURE",
  CONTENT_CREATION_SUCCESS: "CONTENT_CREATION_SUCCESS",

  GET_CONTENT_FAILURE: "GET_CONTENT_FAILURE",
  GET_CONTENT_SUCCESS: "GET_CONTENT_SUCCESS",
  
  //get content by TAG
  GET_BY_TAG_CONTENT_FAILURE: "GET_BY_TAG_CONTENT_FAILURE",
  GET_BY_TAG_CONTENT_SUCCESS: "GET_BY_TAG_CONTENT_SUCCESS",

  //get content by ID
  GET_CONTENT_BY_ID_FAILURE: "GET_CONTENT_BY_ID_FAILURE",
  GET_CONTENT_BY_ID_SUCCESS: "GET_CONTENT_BY_ID_SUCCESS",

  DELETE_CONTENT_BY_ID_FAILURE: "DELETE_CONTENT_BY_ID_FAILURE",
  DELETE_CONTENT_BY_ID_SUCCESS: "DELETE_CONTENT_BY_ID_SUCCESS",

  CONTENT_UPDATE_FAILURE: "CONTENT_UPDATE_FAILURE",
  CONTENT_UPDATE_SUCCESS: "CONTENT_UPDATE_SUCCESS",

  // Up trending
  UP_TRENDING_CREATION_FAILURE: "UP_TRENDING_CREATION_FAILURE",
  UP_TRENDING_CREATION_SUCCESS: "UP_TRENDING_CREATION_SUCCESS",

  CLEAR_NEWENTRY: "CLEAR_NEWENTRY",
  CLEAR_ERROR:"CLEAR_ERROR"
};
