import React from "react";
import { useDispatch } from "react-redux";
import { navbarAction } from "../state/actions";
import styles from '../Styles/header.module.css';
function SearchComponent() {
  const dispatch = useDispatch();

  // Debounce search function
  const debounceFun = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const searchClientFunc = debounceFun((value) => {
    let token = localStorage.getItem("auth-token");
    if (token) {
      dispatch(navbarAction.getSearchData(value, "admin"));
    } else {
      dispatch(navbarAction.getSearchData(value, "user"));
    }
  }, 1000);
  return (
    <div style={{ position: "sticky", top: 0, minHeight: "50px", zIndex: 10 }}>
      <input
        type="search"
        id="searchInput"
        placeholder="Search here..."
        className={styles.searchinput}
        allowClear
        enterButton={false}
        size="large"
        style={{
          border: "black 2px solid",
          borderRadius: "8px",
          outlineColor: "black",
          textAlign: "start",
          paddingLeft: "10px"
        }}
        onChange={(e) => {
          return (
            e.target.value.trim().length > 0 && searchClientFunc(e.target.value)
          );
        }}
      />
    </div>
  );
}
export { SearchComponent };
