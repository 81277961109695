import { homeConstants } from "./action-types";
import { commonApi } from "../../Common/utils/api";
import { startLoading, stopLoading } from "../../state/actions";
import { routesConstants } from "../../Common/utils/allroutes";

/**
 * To update Navbar title action
 * @param {string}
 */

const HomeModalError = (err) => {
  return {
    type: homeConstants.GET_HOME_DATA_FAILURE,
    homeDataError: err,
  };
};

const HomeModalSuccess = (data) => {
  return {
    type: homeConstants.GET_HOME_DATA_SUCCESS,
    homeData: data,
  };
};

const getHomeModalData = () => {
  return (dispatch) => {
    dispatch(startLoading());
    const path = routesConstants.home.get;
    commonApi
      .getApiRequest(path)
      .then((response) => {
        dispatch(HomeModalSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(HomeModalError(err));
        dispatch(stopLoading());
      });
  };
};

export const homeAction = {
  getHomeModalData,
};
