import { appconstants } from "./action-types";
export const startLoading = () => ({
  type: appconstants.START_LOADING,
});

export const stopLoading = () => ({
  type: appconstants.STOP_LOADING,
});

export const startDailyscoopLoading = () => ({
  type: appconstants.START_DAILY_SCOOP_LOADING,
});

export const stopDailyscoopLoading = () => ({
  type: appconstants.STOP_DAILY_SCOOP_LOADING,
});

export const showMessageSuccess = (message) => ({
  type: appconstants.MESSAGE_SUCCESS,
  message,
});

export const showMessageError = (message) => ({
  type: appconstants.MESSAGE_ERROR,
  message,
});

export const showMessageMagazineSuccess = (message) => ({
  type: appconstants.MESSAGE_MAG_SUCCESS,
  message,
});

export const showMessageMagazineError = (message) => ({
  type: appconstants.MESSAGE_MAG_ERROR,
  message,
});

export const showMessageIdSuccess = (message) => ({
  type: appconstants.MESSAGE_ID_SUCCESS,
  message,
});
export const showMessageIdError = (message) => ({
  type: appconstants.MESSAGE_ID_ERROR,
  message,
});
export const messageClear = () => ({
    type:appconstants.MESSAGE_CLEAR
});

export const startLoadingSearch = () => ({
  type: appconstants.START_LOADING_SEARCH,
});

export const stopLoadingSearch = () => ({
  type: appconstants.STOP_LOADING_SEARCH,
});

