import { womenLeadersCoverStoriesConstants } from "./action-types";

const initialState = {
  womenLeadersAndCoverStoriesData: [],
  womenLeadersAndCoverStoriesDataLoading: true,
  womenLeadersAndCoverStoriesDataError: null,
  size: 0,
};

const womenLeadersAndCoverStoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case womenLeadersCoverStoriesConstants.GET_WOMEN_LEADERS_DATA_SUCCESS:
      return {
        ...state,
        womenLeadersAndCoverStoriesData:
          action.WomenLeaderAndCoverData.info.data,
        size: action.WomenLeaderAndCoverData.info.totalCount,
        womenLeadersAndCoverStoriesDataLoading: true,
        womenLeadersAndCoverStoriesDataError: null,
      };
    case womenLeadersCoverStoriesConstants.GET_WOMEN_LEADERS_DATA_FAILURE:
      return {
        ...state,
        womenLeadersAndCoverStoriesData: [],
        womenLeadersAndCoverStoriesDataLoading: false,
        womenLeadersAndCoverStoriesDataError: action.WomenLeaderAndCoverError,
      };
    case womenLeadersCoverStoriesConstants.GET_COVER_STORIES_DATA_SUCCESS:
      return {
        ...state,
        womenLeadersAndCoverStoriesData:
          action.WomenLeaderAndCoverData.info.data,
        size: action.WomenLeaderAndCoverData.info.totalCount,
        womenLeadersAndCoverStoriesDataLoading: true,
        womenLeadersAndCoverStoriesDataError: null,
      };
    case womenLeadersCoverStoriesConstants.GET_COVER_STORIES_DATA_FAILURE:
      return {
        ...state,
        womenLeadersAndCoverStoriesData: [],
        womenLeadersAndCoverStoriesDataLoading: false,
        womenLeadersAndCoverStoriesDataError: action.WomenLeaderAndCoverError,
      };

    default:
      return state;
  }
};
export { womenLeadersAndCoverStoriesReducer };
