import React from "react";
import { Row, Col, Typography } from "antd";
import { FaPhoneVolume, FaXTwitter } from "react-icons/fa6";
import {
  EnvironmentFilled,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailFilled,
  YoutubeOutlined,
} from "@ant-design/icons";
import styles from "./Styles/footer.module.css";
import { Link } from "react-router-dom";
const { Text } = Typography;

const FooterComp = () => {
  return (
    <>
      <div className={styles.footerContent} style={{ padding: "0" }}>
        <Row gutter={[16, 30]}>
          {/* 1st column */}
          <Col
            sm={8}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            className={styles.newColu}
            style={{
              borderLeft: "1px solid white",
              paddingLeft: "20px",
            }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                About us
              </Text>
            </div>
            <Text style={{ display: "block" }} className={styles.description}>
              Visionary Vogues is where the chronicles of business evolution
              unfold. We are a leading online magazine dedicated to celebrating
              the visionaries, disruptors, and transformative leaders who
              reshape entire industries.
            </Text>
          </Col>

          {/* 2nd column */}
          <Col
            sm={8}
            md={8}
            lg={6}
            xl={6}
            xxl={6}
            style={{ borderLeft: "1px solid white", paddingLeft: "20px" }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                Editorial Enquiries
              </Text>
            </div>
            <Text
              style={{ display: "block", width: "100%" }}
              className={styles.description}
            >
              For editorial enquiries contact <br />
              <div>
                <a
                  href="mailto:info@visionaryvogues.com"
                  data-ajax="false"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "15px",
                    marginTop: "10px",
                    color: "white",
                  }}
                >
                  <MailFilled className={styles.icon} /> &nbsp;
                  info@visionaryvogues.com
                </a>
              </div>
            </Text>
          </Col>

          {/* 3rd column */}
          <Col
            sm={8}
            md={8}
            lg={5}
            xl={5}
            xxl={5}
            style={{ borderLeft: "1px solid white", paddingLeft: "20px" }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                Reach us
              </Text>
            </div>

            <Text style={{ display: "block" }} className={styles.description}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "20px",
                  marginBottom: "15px",
                }}
              >
                <EnvironmentFilled className={styles.icon} /> 77 Water St,
                Brooklyn, New York, NY 11201, United States.
              </div>
            </Text>
            <Text style={{ display: "block" }} className={styles.description}>
              <div>
                <a
                  href="tel:+13479832711"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "20px",
                    color: "white",
                  }}
                >
                  <FaPhoneVolume className={styles.icon} /> +1 347 983 2711
                </a>
              </div>
            </Text>
          </Col>

          {/* 4th column */}
          <Col
            sm={8}
            md={8}
            lg={5}
            xl={5}
            xxl={5}
            style={{ borderLeft: "1px solid white", paddingLeft: "20px" }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                social media
              </Text>
            </div>

            <div className={styles.socialMediaIcons}>
              <Link to="https://youtube.com/@VisionaryVogues?si=LlKhzYpHm29Ltfjw">
                <YoutubeOutlined
                  style={{ fontSize: "30px", color: "#ffffff" }}
                />
              </Link>
              <Link to="https://www.instagram.com/visionary_vogues/">
                <InstagramOutlined
                  style={{ fontSize: "25px", color: "#ffffff" }}
                />
              </Link>
              <Link to="https://www.facebook.com/share/9d7EWWap6E1Feii1/?mibextid=qi2Omg">
                <FacebookOutlined
                  style={{ fontSize: "25px", color: "#ffffff" }}
                />
              </Link>

              <Link to="https://www.linkedin.com/company/102499826/admin/feed/posts/">
                <LinkedinOutlined
                  style={{ fontSize: "25px", color: "#ffffff" }}
                />
              </Link>
              <Link to="https://x.com/visionaryvogues">
                <FaXTwitter style={{ fontSize: "25px", color: "#ffffff" }} />
              </Link>
            </div>
          </Col>

          {/* 5th column */}
          <Col
            sm={8}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            style={{
              borderLeft: "1px solid white",
              paddingLeft: "20px",
              width: "100%",
            }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                visonary vogues magazine
              </Text>
            </div>

            <div className={styles.listing}>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/aboutus">About Page</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/subscribe">Subscribe</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/contactUs">Contact</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/magazine-archives">Magazine Archives</Link>
              </Text>
            </div>
          </Col>

          {/* 6th column */}
          <Col
            sm={8}
            md={8}
            lg={6}
            xl={6}
            xxl={6}
            style={{
              borderLeft: "1px solid white",
              paddingLeft: "20px",
              width: "100%",
            }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                features
              </Text>
            </div>

            <div className={styles.listing}>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/magazines">Magazines</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/cover-stories">Cover Stories</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/women-leaders">Women Leaders</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/special-profile">Special Profile</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop">Daily Scoop</Link>
              </Text>

              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/up-trending">Up-Trending</Link>
              </Text>
            </div>
          </Col>

          {/* 7th column */}
          <Col
            sm={8}
            md={8}
            lg={5}
            xl={5}
            xxl={5}
            style={{
              borderLeft: "1px solid white",
              paddingLeft: "20px",
              width: "100%",
            }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                categories
              </Text>
            </div>

            <div className={styles.listing}>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/technology">Technology</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/business">Business</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/finance">Finance</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/marketing">Marketing</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/healthcare">Healthcare</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/consulting">Consulting</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/education">Education</Link>
              </Text>
              {/* <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/leadership">Leadership</Link>
              </Text> */}
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/manufacturing">Manufacturing</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/sportsAndFitness">Sports and Fitness</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/daily-scoop/other">Other</Link>
              </Text>
            </div>
          </Col>

          {/* 8th column */}
          <Col
            sm={8}
            md={8}
            lg={5}
            xl={5}
            xxl={5}
            style={{
              borderLeft: "1px solid white",
              paddingLeft: "20px",
              width: "100%",
            }}
          >
            <div className={styles.titleWrapper}>
              <Text style={{ display: "block" }} className={styles.title}>
                resources
              </Text>
            </div>

            <div className={styles.listing}>
              {/* <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/guest-post">Submit Guest Posts</Link>
              </Text> */}
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/sponsored-post">Submit Guest Posts</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/disclaimer">Disclaimer</Link>
              </Text>
              <Text style={{ display: "block" }} className={styles.listingText}>
                <Link to="/terms-of-use">Terms Of Use</Link>
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export { FooterComp };
