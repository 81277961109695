import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Typography, Empty } from "antd";
import { v4 as uuidv4 } from "uuid";
import VerticalCard from "../Common/Components/VerticalCard";
import { useDispatch, useSelector } from "react-redux";
import { magazineAction } from "./state/actions";
import styles from "./Styles/magazinesArchive.module.css";
import Spinner from "../Spinner/Spinner";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Content } = Layout;
const { Title } = Typography;

const MagazinesArchive = () => {
  const dispatch = useDispatch();
  const { magazineData, magazineYear } = useSelector(
    (state) => state.magazineReducer
  );
  let years = magazineData?.map((data) => data._id);
  const { isLoading, message, magmessage } = useSelector(
    (state) => state.loadingReducer
  );
  const [index, setIndex] = useState(0);

  useEffect(() => {
    dispatch(magazineAction.getMagazineArchiveModalData());
    if (years?.[0])
      dispatch(magazineAction.getMagazineByYearModalData(years?.[0]));
  }, [dispatch, years?.[0]]);

  const handleClick = (e, i) => {
    dispatch(
      magazineAction.getMagazineByYearModalData(parseInt(e.target.innerHTML))
    );
    setIndex(i);
  };

  if (isLoading) {
    return <Spinner />;
  } else if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  } else if (typeof magmessage === "object") {
    return (
      <ErrorHandling
        code={magmessage?.status}
        statusText={magmessage?.statusText}
      />
    );
  }
  return (
    <div>
      <Helmet>
        <meta name="keywords" content={constants.magazineArchives.title}></meta>
        <title>{constants.magazineArchives.title}</title>
        <meta name="description" content={constants.magazineArchives.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.magazineArchives.title} />
        <meta name="twitter:description" content={constants.magazineArchives.description} />

        <meta property="og:title" content={constants.magazineArchives.title} />
        <meta property="og:description" content={constants.magazineArchives.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/magazine-archives`} />
      </Helmet>
      <Content>
        <div className={styles.dailyScoopTitleWrapper}>
          <Title
            style={{ display: "block", marginBottom: "0px" }}
            className={styles.dailyScoopText}
          >
            magazines
          </Title>
          <Title
            style={{ display: "block", marginTop: "0px" }}
            className={styles.dailyScoopTitle}
          >
            archives
          </Title>
        </div>
        {years?.length === 0 || years === undefined ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <Row className={styles.yearButtonsWrapper} gutter={[16, 16]}>
            {years?.map((year, i) => (
              <Col xs={12} sm={8} md={4} lg={4} xl={4} xxl={4} key={uuidv4()}>
                <div className={styles.year} onClick={(e) => handleClick(e, i)}>
                  <p
                    className={
                      index === i ? styles.yearButtonSelect : styles.yearNormal
                    }
                  >
                    {year}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        )}
        {magazineYear?.length === 0 || magazineYear === undefined ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <Row style={{ margin: "20px" }}>
            {magazineYear?.map((magazine) => (
              <Col key={uuidv4()} xs={12} sm={12} md={8} lg={6}>
                <VerticalCard
                  image={magazine?.thumbnail}
                  title={magazine?.title}
                  type={"magazine"}
                  magazinePath={magazine?.path}
                  magazin={"true"}
                  id={magazine?.redirectURL}
                />
              </Col>
            ))}
          </Row>
        )}
      </Content>
    </div>
  );
};

export { MagazinesArchive };
