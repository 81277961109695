import React from "react";
import { Col, Layout, Row, Typography } from "antd";
import styles from "../Styles/aboutSection.module.css";

const { Content } = Layout;
const { Title, Text } = Typography;

const AboutSection = () => {
  return (
    <Content className={styles.aboutContent}>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className={styles.aboutTitleWrapper}
      >
        <Title className={styles.aboutTitle}>
          About Visionary Vogues <br />
          magazine
        </Title>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <hr
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "15px",
            border: "none",
          }}
        />
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className={styles.aboutDescriptionWrapper}
      >
        <Col xs={3} sm={3} md={3} lg={4}></Col>
        <Col xs={18} sm={18} md={18} lg={16} style={{textAlign:'center'}}>
          <Text className={styles.aboutDescription}>
            Visionary Vogues Magazine is a platform for leaders, entrepreneurs,
            and organizations from across the globe to showcase their stories.
            We feature stories that are inspiring, unique, and extremely
            insightful. In just a short span, we have garnered a growing reader
            base and we would like to welcome you aboard in this journey. Our
            platform promises to share with you quality and nothing but quality.
            Join us and embrace excellence.
          </Text>
        </Col>
        <Col xs={3} sm={3} md={3} lg={4}></Col>
      </Row>
    </Content>
  );
};

export default AboutSection;
