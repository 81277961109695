const constants = {
  home: {
    title: "Home | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  contact: {
    title: "Contact | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  dailyscoop: {
    title: "Daily Scoop | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  uptrending: {
    title: "Up-Trending | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  sponsoredGuestPost: {
    title: "Sponsored Guest Post | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  specialProfile: {
    title: "Special Profile | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  magazines: {
    title: "Magazines | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  subscribe: {
    title: "Subscribe | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  womenLeaders: {
    title: "Women Leaders | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  coverstories: {
    title: "Cover Stories | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  aboutUs: {
    title: "About Us | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  disclaimer: {
    title: "Disclaimer | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  privacyPolicy: {
    title: "Privacy Policy | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  termsOfUse: {
    title: "Terms Of Use | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  magazineArchives: {
    title: "Magazine Archives | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  },
  dailyScoopTag: {
    title: "Daily scoop | Visionary Vogues",
    description: "Visionary Vogue: Where Business Transformation Takes Center Stage",
    image: "",
  }
};

module.exports = { constants };
