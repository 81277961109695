import React, { useEffect, useState } from "react";
import { Col, Empty, Layout, Pagination, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import styles from "./Styles/upTrending.module.css";
import VerticalCard2 from "../Common/Components/VerticalCard2";
import { useDispatch, useSelector } from "react-redux";
import { upTrendingAction } from "./state/actions";
import Spinner from "../Spinner/Spinner";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";

const { Content } = Layout;
const { Title } = Typography;

const UpTrending = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { newEntry, totalCount } = useSelector(
    (state) => state.upTrendingReducer
  );
  const { isLoading, message } = useSelector((str) => str.loadingReducer);

  const handlePageChange = (page) => {
    setPage(page);
    dispatch(upTrendingAction.getUpTrendingData("user", page));
  };

  useEffect(() => {
    dispatch(upTrendingAction.getUpTrendingData("user", 1));
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }
  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }
  return (
    <Content style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta name="keywords" content={constants.uptrending.title}></meta>
        <title>{constants.uptrending.title}</title>
        <meta name="description" content={constants.uptrending.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.uptrending.title} />
        <meta name="twitter:description" content={constants.uptrending.description} />

        <meta property="og:title" content={constants.uptrending.title} />
        <meta property="og:description" content={constants.uptrending.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/up-trending`} />
      </Helmet>
      
      <div className={styles.dailyScoopTitleWrapper}>
        <Title className={styles.dailyScoopTitle}>up-trending</Title>
      </div>

      {newEntry?.length === 0 || newEntry === undefined ? (
        <Empty
          style={{ marginTop: "100px" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row
          gutter={[16, 16]}
          style={{
            paddingTop: "40px",
            overflowX: "hidden",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {newEntry?.map((data) => (
            <Col xs={24} sm={12} md={8} lg={6} key={uuidv4()}>
              <VerticalCard2
                image={data?.thumbnail}
                title={data?.title}
                description={data?.description}
                textType={1}
                id={data?.redirectURL}
                button={"Read More"}
              />
            </Col>
          ))}
        </Row>
      )}
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <Pagination
          defaultCurrent={1}
          total={totalCount}
          onChange={handlePageChange}
          current={page}
          pageSize={12}
          hideOnSinglePage={true}
          showSizeChanger={false}
        />
      </Row>
    </Content>
  );
};

export default UpTrending;
