import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Layout } from "antd";
import MainSection from "./Components/MainSection";
import AboutSection from "./Components/AboutSection";
import MagazineSubscribe from "./Components/MagazineSubscribe";
import { homeAction } from "./state/actions";
import MagazineSection from "./Components/MagazineSection";
import DailyScoop from "./Components/DailyScoop";
import UpTrending from "./Components/UpTrending";
import Spinner from "../Spinner/Spinner";
import axios from "axios";
import { constants } from "../Common/Components/Constants";
import { Helmet } from "react-helmet-async";

const { Content } = Layout;

function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(homeAction.getHomeModalData());
  }, [dispatch]);

  const { homeData } = useSelector((state) => state.homeReducer);

  const { isLoading } = useSelector((str) => str.loadingReducer);
  if (isLoading) {
    return <Spinner />;
  }

  let handleTest = () => {
    var config = {
      method: "post",
      url: "https://outpost.mappls.com/api/security/oauth/token?grant_type=client_credentials&client_id=96dHZVzsAuspbz7R_o3HRgwY1yIm-v54S0z7vKe57PcR-feShpBDNqQHtRm_4zS7-eJlajmXsDzOW9r9n7OUjw%3D%3D&client_secret=lrFxI-iSEg8xDVYD99j8FKGHZxRw5XV6XrMBrAWKu4Sbp-yIHr0tV8hg01tkdfHpDaIdkvHiWkH582aFOeRYnDM_9Q_w69Tz",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Length": 0,
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Layout style={{ height: "100%", background: "#fff" }}>
      <Helmet>
        <meta name="keywords" content={constants.home.title}></meta>
        <title>{constants.home.title}</title>
        <meta name="description" content={constants.home.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.home.title} />
        <meta name="twitter:description" content={constants.home.description} />

        <meta property="og:title" content={constants.home.title} />
        <meta property="og:description" content={constants.home.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/`} />
      </Helmet>
      {/* <Button onClick={handleTest}>Click</Button> */}
      <Content style={{ background: "#fff", overflowX: "hidden" }}>
        <MainSection />
      </Content>
      <Content style={{ background: "#fff", overflowX: "hidden" }}>
        <AboutSection />
      </Content>
      <Content style={{ background: "#fff", overflowX: "hidden" }}>
        <MagazineSection magazines={homeData?.magazines} />
      </Content>
      <Content style={{ background: "#fff", overflowX: "hidden" }}>
        <MagazineSubscribe
          magazines={homeData?.magazines}
          onCover={homeData?.onCover}
        />
      </Content>
      <Content style={{ background: "#fff", overflowX: "hidden" }}>
        <DailyScoop dailyScoops={homeData?.dailyScoops} />
      </Content>
      <Content style={{ background: "#fff", overflowX: "hidden" }}>
        <UpTrending upTrending={homeData?.upTrending} />
      </Content>
    </Layout>
  );
}
export { Home };
