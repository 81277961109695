import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import VerticalCard from "../Common/Components/VerticalCard";
import { Col, Divider, Empty, Layout, Pagination, Row } from "antd";
import styles from "./Styles/magazines.module.css";
import { useDispatch, useSelector } from "react-redux";
import { magazineAction } from "./state/actions";
import Spinner from "../Spinner/Spinner";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Content } = Layout;

const Magazines = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { magazineData, totalCount } = useSelector(
    (state) => state.magazineReducer
  );
  const { isLoading, message } = useSelector((str) => str.loadingReducer);
  useEffect(() => {
    dispatch(magazineAction.getMagazineModalData("user", 1));
  }, [dispatch]);

  const handlePageChange = (page) => {
    setPage(page);
    dispatch(magazineAction.getMagazineModalData("user", page));
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }

  return (
    <div>
      <Helmet>
        <meta name="keywords" content={constants.magazines.title}></meta>
        <title>{constants.magazines.title}</title>
        <meta name="description" content={constants.magazines.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.magazines.title} />
        <meta name="twitter:description" content={constants.magazines.description} />

        <meta property="og:title" content={constants.magazines.title} />
        <meta property="og:description" content={constants.magazines.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/magazines`} />
      </Helmet>
      <Content>
        <h2 className={styles.magazinesTitle}>Magazines</h2>
        <Divider style={{ backgroundColor: "black" }} />
      </Content>
      <Content>
        {magazineData?.length === 0 || magazineData === undefined ? (
          <Empty
            style={{ marginTop: "100px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <Row style={{ margin: "20px", justifyContent: "center" }}>
            {magazineData?.map((magazine) => (
              <Col key={uuidv4()} xs={24} sm={12} md={8} lg={7} xl={6}>
                <VerticalCard
                  image={magazine?.thumbnail}
                  title={magazine?.title}
                  type={"magazine"}
                  id={magazine?.redirectURL}
                  magazin={"true"}
                />
              </Col>
            ))}
          </Row>
        )}
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          <Pagination
            defaultCurrent={1}
            total={totalCount}
            onChange={handlePageChange}
            current={page}
            pageSize={12}
            hideOnSinglePage={true}
            showSizeChanger={false}
          />
        </Row>
      </Content>
    </div>
  );
};

export { Magazines };
