import React from "react";
import { Col, Collapse, Input, Row } from "antd";
import { ImageUpload } from "../../Common/Components/ImageUpload";
import { CaretRightFilled } from "@ant-design/icons";

const { TextArea } = Input;
const EditMagazine = ({ Form, normFile, normFilePoster }) => {
  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <CaretRightFilled
          style={{ fontSize: "1.2em" }}
          rotate={isActive ? 90 : 0}
        />
      )}
      style={{ fontWeight: 500 }}
      size="large"
      items={[
        {
          key: "1",
          label: "Advanced Tools",
          style: { textAlign: "left" },
          children: (
            <>
              {/* Thumbnail */}
              <Form.Item
                label="PDF url"
                name={"pdfURL"}
                rules={[
                  {
                    required: true,
                    message: "Please add PDF URL!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="PDF url" />
              </Form.Item>
              {/* Thumbnail */}
              <Form.Item
                label="Thumbnail"
                name="thumbnail"
                rules={[
                  {
                    required: true,
                    message: "Please add Thumbnail!",
                  },
                ]}
                getValueFromEvent={normFile}
              >
                <ImageUpload origin="thumbnail" maxCount={1} />
              </Form.Item>
              {/* Poster */}
              <Form.Item
                label="Poster"
                name="poster"
                getValueFromEvent={normFilePoster}
              >
                <ImageUpload origin="poster" maxCount={1} />
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Author Name"
                    name={"authorName"}
                    rules={[
                      {
                        required: true,
                        message: "Please add author name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Publishing Year"
                    name="publishingYear"
                    rules={[
                      {
                        required: true,
                        message: "Please add Publishing Year!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label="Key words"
                    name="keywords"
                    rules={[
                      {
                        required: true,
                        message: "Please add Keywords!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ),
        },
      ]}
    />
  );
};

export default EditMagazine;
