import { contactUsConstants } from "./action-types";
import { contactUsApi } from "../utils/api";
import { routesConstants } from "../../Common/utils/allroutes";

/**
 * To update Navbar title action
 * @param {string}
 */

const ContactUsModalError = (err) => {
  return {
    type: contactUsConstants.GET_CONTACT_US_DATA_FAILURE,
    magazineDataError: err,
  };
};

const ContactUsModalSuccess = (data) => {
  return {
    type: contactUsConstants.GET_CONTACT_US_DATA_SUCCESS,
    contactUsData: data,
  };
};

const getContactUsModalData = () => {
  return (dispatch) => {
    let path = routesConstants.contactUs.get;

    contactUsApi
      .getApiRequest(path)
      .then((response) => {
        dispatch(ContactUsModalSuccess(response.data.info));
      })
      .catch((err) => {
        dispatch(ContactUsModalError(err));
      });
  };
};

const AddContactUsModalError = (err) => {
  return {
    type: contactUsConstants.ADD_CONTACT_DATA_FAILURE,
    magazineDataError: err,
  };
};

const AddContactUsModalSuccess = (data) => {
  return {
    type: contactUsConstants.ADD_CONTACT_DATA_SUCCESS,
    contactUsData: data,
  };
};

const AddContactUsModalData = (data, source) => {
  return (dispatch) => {
    let path = routesConstants.contactUs.post;

    contactUsApi
      .addDataRequest(data, source, path)
      .then((response) => {
        dispatch(AddContactUsModalSuccess(response.data.info));
      })
      .catch((err) => {
        dispatch(AddContactUsModalError(err));
      });
  };
};

export const contactUsAction = {
  getContactUsModalData,
  AddContactUsModalData,
};
