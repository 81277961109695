import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import htmlEditButton from "quill-html-edit-button";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register(Block, true);
Quill.register("modules/imageResize", ImageResize);

function ReactQuillComponentRender({ value, onChange, setDescValue }) {
  const [initialvalue, setInitialvalue] = useState(value);
  const handlechange = (e) => {
    setInitialvalue(e);
    // onChange(e);
    setDescValue(e);
  }

  useEffect(() => {
    setInitialvalue(value);
  }, [value]);

  return (
    <div>
      <ReactQuill
        theme="snow"
        name="description"
        modules={{
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "header",
              "image",
              "video",
              "strike",
              "blockquote",
              { color: [] },
              { background: [] },
            ],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["link"],
            ["clean"],
          ],
          htmlEditButton: {
            msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: "Ok", // Text to display in the OK button, default: Ok,
            cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
            buttonHTML: "HTML", // Text to display in the toolbar button, default: <>
            buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {}, // The default mod
          },
          imageResize: {
            parchment: Quill.import("parchment"),
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "video",
          "color",
          "background",
        ]}
        value={initialvalue}
        style={{ overflow: "auto" }}
        onChange={handlechange}
        placeholder={"Write something..."}
      />
    </div>
  );
}
export { ReactQuillComponentRender };