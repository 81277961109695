import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Typography,
  Image,
  Checkbox,
  Tag,
  Divider,
  Space,
  Switch,
  Popconfirm,
  notification,
  Row,
  Col,
} from "antd";
import { EditOutlined, FileImageOutlined } from "@ant-design/icons";
import { FaTrashCan } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { clearError, dashboardAction } from "./state/actions";
import { TableComponentDetails } from "./Components/TableComponentDetails";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
const { Paragraph } = Typography;

function Dashboard() {
  const { type } = useParams();

  const dispatch = useDispatch();

  let { entries, totalCount, error } = useSelector(
    (state) => state.dashboardReducer
  );
  const { message } = useSelector((str) => str.loadingReducer);
  const [activePage, setActivePage] = useState(1);
  const intervalRef = useRef(1);
  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const onClickf = (checked, event) => {
    dispatch(
      dashboardAction.dashboardUpdateContentEvent(
        { active: checked },
        event.redirectURL,
        type === undefined ? "business" : type,
        intervalRef.current
      )
    );
  };

  const onClick = (checked, event) => {
    dispatch(
      dashboardAction.dashboardUpdateContentEvent(
        { active: checked },
        event._id,
        type === undefined ? "business" : type,
        intervalRef.current
      )
    );
  };
  const onCoverStoryClick = (checked, event) => {
    const formData = new FormData();
    formData.append("isCoverStory", checked.target.checked);
    dispatch(
      dashboardAction.dashboardUpdateContentEvent(
        formData,
        event.redirectURL,
        type,
        intervalRef.current
      )
    );
  };

  const onWomenLeadersClick = (checked, event) => {
    const formData = new FormData();
    formData.append("isWomenLeaders", checked.target.checked);
    dispatch(
      dashboardAction.dashboardUpdateContentEvent(
        formData,
        event.redirectURL,
        type,
        intervalRef.current
      )
    );
  };
  const HandleSoftDelete = (event) => {
    dispatch(
      dashboardAction.dashboardUpdateContentEvent(
        { isDeleted: !event.isDeleted },
        event.redirectURL,
        type,
        intervalRef.current
      )
    );
  };
  const HandleSoftDeleteInquiries = (event) => {
    dispatch(
      dashboardAction.dashboardUpdateContentEvent(
        { isDeleted: !event.isDeleted },
        event._id,
        type,
        intervalRef.current
      )
    );
  };

  const fetchRecords = (page) => {
    setActivePage(page);
    intervalRef.current = page;
    dispatch(dashboardAction.dashboardGetContentEvent(type, "admin", page));
  };
  useEffect(() => {
    if (error?.response?.data?.message) {
      api.error({
        message: error?.response?.data?.message,
      });
    }
    dispatch(clearError());
  }, []);

  // const search = useMemo(() => {
  //   return Array.from(searchParams.entries()).reduce((acc, [key, val]) => {
  //     acc[key] = val;
  //     return acc;
  //   }, {});
  // }, [searchParams]);
  const search = location.state?.search

  useEffect(() => {
    dispatch(
      dashboardAction.dashboardGetContentEvent(
        type === undefined ? "business" : type,
        "admin",
        1,
        search
      )
    );
  }, [type, search]);

  const dailyScoopsColumns = [

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180,
      align: "center",
      render: (_, event) => (
        <Space align="baseline">
          <Link
            to={`/dashboard/addContent/${event?.tag ? event?.tag : type}/${event.redirectURL
              }`}
          >
            <EditOutlined
              style={{
                fontSize: "19px",
                cursor: "pointer",
              }}
            />
          </Link>
          <Switch
            // defaultChecked
            checked={event.active}
            onClick={(checked) => onClickf(checked, event)}
            style={{ width: "fit-content" }}
          />
          <Popconfirm
            title="Sure to delete temporarily?"
            onConfirm={() => HandleSoftDelete(event)}
          >
            <FaTrashCan
              style={{
                color: "red",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.title}
          </Paragraph>
        );
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   ellipsis: true,
    //   width: 200,
    //   align: "center",
    // },
    {
      title: "Key words",
      dataIndex: "keywords",
      key: "keywords",
      ellipsis: true,
      width: 200,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Row>
            <Col span={24}>{dayjs(event.createdAt).format("DD-MM-YYYY")}</Col>
            {event?.scheduledActivationTime &&
              <Col span={24} style={{ textWrap: "wrap" }}>Scheduled At: {dayjs(event?.scheduledActivationTime).format("MMM D, YYYY h:mm A")}</Col>
            }
          </Row>
        );
      },
    },
    {
      title: "Redirect URL",
      dataIndex: "redirectURL",
      key: "redirectURL",
      ellipsis: true,
      width: 200,
      align: "center",
    },

    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Image
            preview={false}
            width={100}
            height={100}
            src={event.thumbnail ? event.thumbnail : <FileImageOutlined />}
          />
        );
      },
    },

  ];

  const magazineColumns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      align: "center",
      render: (_, event) => {
        return (
          <Space align="baseline">
            <Link to={`/dashboard/addContent/${type}/${event.redirectURL}`}>
              <EditOutlined
                style={{
                  fontSize: "19px",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Switch
              // defaultChecked
              checked={event.active}
              onClick={(checked) => onClickf(checked, event)}
              style={{ width: "fit-content" }}
            />
            <Popconfirm
              title="Sure to delete temporarily?"
              onConfirm={() => HandleSoftDelete(event)}
            >
              <FaTrashCan
                style={{
                  color: "red",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.title}
          </Paragraph>
        );
      },
    },
    {
      title: "Key words",
      dataIndex: "keywords",
      key: "keywords",
      ellipsis: true,
      width: 200,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Row>
            <Col span={24}>{dayjs(event.createdAt).format("DD-MM-YYYY")}</Col>
            {event?.scheduledActivationTime &&
              <Col span={24} style={{ textWrap: "wrap" }}>Scheduled At: {dayjs(event?.scheduledActivationTime).format("MMM D, YYYY h:mm A")}</Col>
            }
          </Row>
        );
      },
    },
    {
      title: "Redirect URL",
      dataIndex: "redirectURL",
      key: "redirectURL",
      ellipsis: true,
      width: 200,
      align: "center",
    },
    {
      title: "PDF url",
      dataIndex: "pdfURL",
      key: "pdfURL",
      width: 200,
      ellipsis: true,
      align: "center",
    },
    {
      title: "Published Year",
      dataIndex: "publishingYear",
      key: "publishingYear",
      width: 200,
      ellipsis: true,
      align: "center",
    },
    {
      title: "Author",
      dataIndex: "authorName",
      key: "authorName",
      width: 200,
      ellipsis: true,
      align: "center",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      ellipsis: true,
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Image
            preview={false}
            width={100}
            height={100}
            src={event.thumbnail ? event.thumbnail : <FileImageOutlined />}
          />
        );
      },
    },
    {
      title: "Poster",
      dataIndex: "poster",
      key: "poster",
      width: 200,
      ellipsis: true,
      align: "center",
      render: (_, event) => {
        return (
          <Image
            preview={false}
            width={100}
            height={100}
            src={event.poster ? event.poster : <FileImageOutlined />}
          />
        );
      },
    },
    {
      title: "Cover Story",
      dataIndex: "iscoverstory",
      key: "iscoverstory",
      width: 110,
      align: "center",
      render: (_, event) => {
        return (
          <Checkbox
            checked={event.isCoverStory}
            onChange={(checked) => onCoverStoryClick(checked, event)}
            style={{ width: "30px" }}
          ></Checkbox>
        );
      },
    },
    {
      title: "Women Leader",
      dataIndex: "iswomenleader",
      key: "iswomenleader",
      width: 110,
      align: "center",
      render: (_, event) => {
        return (
          <Checkbox
            checked={event.isWomenLeaders}
            onChange={(checked) => onWomenLeadersClick(checked, event)}
            style={{ width: "30px" }}
          ></Checkbox>
        );
      },
    },

  ];

  const InquirieColumns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      align: "center",
      render: (_, event) => (
        <Space align="baseline">
          <Switch
            // defaultChecked
            checked={event.active}
            onClick={(checked) => onClick(checked, event)}
            style={{ width: "fit-content" }}
          />
          <Popconfirm
            title="Sure to delete temporarily?"
            onConfirm={() => HandleSoftDeleteInquiries(event)}
          >
            <FaTrashCan
              style={{
                color: "red",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.name}
          </Paragraph>
        );
      },
    },
    // {
    //   title: "Key words",
    //   dataIndex: "keywords",
    //   key: "keywords",
    //   ellipsis: true,
    //   width: 200,
    //   align: "center",
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Row>
            <Col span={24}>{dayjs(event.createdAt).format("DD-MM-YYYY")}</Col>
          </Row>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.email}
          </Paragraph>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 150,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.company}
          </Paragraph>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.city}
          </Paragraph>
        );
      },
    },
    {
      title: "Sponsored details",
      dataIndex: "sponsoredDetails",
      key: "sponsoredDetails",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.sponsoredPostDetails}
          </Paragraph>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 300,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.message}
          </Paragraph>
        );
      },
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.website}
          </Paragraph>
        );
      },
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      width: 250,
      align: "center",
      render: (_, event) => {
        return <Image src={event?.file} alt={event?.title} preview={false} />;
      },
    },
    {
      title: "ID / Source",
      dataIndex: "source",
      key: "source",
      width: 250,
      render: (_, event) => {
        return (
          <>
            <Paragraph>ID: {event._id}</Paragraph>
            <Divider style={{ marginBottom: "15px", marginTop: "2px" }} />
            <div>
              <Paragraph style={{ display: "inline" }}>Source: </Paragraph>
              &nbsp;
              <Tag
                color={
                  event.source === "guest"
                    ? "warning"
                    : event.source === "contact"
                      ? "volcano"
                      : event.source === "enquire"
                        ? "green"
                        : "purple"
                }
                style={{ fontSize: "15px" }}
              >
                {event.source}
              </Tag>
            </div>
          </>
        );
      },
    },


  ];

  const specialProfileColumns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180,
      align: "center",
      render: (_, event) => (
        <Space align="baseline">
          <Link
            to={`/dashboard/addContent/${event?.tag ? event?.tag : type}/${event.redirectURL
              }`}
          >
            <EditOutlined
              style={{
                fontSize: "19px",
                cursor: "pointer",
              }}
            />
          </Link>
          <Switch
            // defaultChecked
            checked={event.active}
            onClick={(checked) => onClickf(checked, event)}
            style={{ width: "fit-content" }}
          />
          <Popconfirm
            title="Sure to delete temporarily?"
            onConfirm={() => HandleSoftDelete(event)}
          >
            <FaTrashCan
              style={{
                color: "red",
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Paragraph
            ellipsis={{
              rows: 2,
              width: 300,
              expandable: true,
              symbol: "more",
            }}
          >
            {event.title}
          </Paragraph>
        );
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   ellipsis: true,
    //   width: 200,
    //   align: "center",
    // },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Row>
            <Col span={24}>{dayjs(event.createdAt).format("DD-MM-YYYY")}</Col>
            {event?.scheduledActivationTime &&
              <Col span={24} style={{ textWrap: "wrap" }}>Scheduled At: {dayjs(event?.scheduledActivationTime).format("MMM D, YYYY h:mm A")}</Col>
            }
          </Row>
        );
      },
    },
    {
      title: "Key words",
      dataIndex: "keywords",
      key: "keywords",
      ellipsis: true,
      width: 200,
      align: "center",
    },
    {
      title: "Redirect URL",
      dataIndex: "redirectURL",
      key: "redirectURL",
      ellipsis: true,
      width: 200,
      align: "center",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Image
            preview={false}
            width={100}
            height={100}
            src={event.thumbnail ? event.thumbnail : <FileImageOutlined />}
          />
        );
      },
    },
    {
      title: "Poster",
      dataIndex: "poster",
      key: "poster",
      width: 200,
      align: "center",
      render: (_, event) => {
        return (
          <Image
            preview={false}
            width={100}
            height={100}
            src={event.poster ? event.poster : <FileImageOutlined />}
          />
        );
      },
    },

  ];

  const [tabType, setTabType] = useState(dailyScoopsColumns);

  useEffect(() => {
    switch (type) {
      case "magazine":
        setActivePage(1);
        setTabType(magazineColumns);
        intervalRef.current = 1;
        break;
      case "inquiries":
        setActivePage(1);
        setTabType(InquirieColumns);
        intervalRef.current = 1;
        break;
      case "specialProfile":
        setActivePage(1);
        setTabType(specialProfileColumns);
        intervalRef.current = 1;
        break;
      default:
        setActivePage(1);
        setTabType(dailyScoopsColumns);
        intervalRef.current = 1;
    }
  }, [type]);

  if (typeof message === "object") {
    return <ErrorHandling />;
  }

  return (
    <>
      {contextHolder}
      <TableComponentDetails
        blogMagazineColumns={tabType}
        dataSource={entries}
        totalCount={totalCount}
        fetchRecords={fetchRecords}
        activePage={activePage}
        search={search}
      />
    </>
  );
}
export { Dashboard };
