import React from "react";
import { Col, Layout, Row, Typography, Empty } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as uuidv4 } from "uuid";
import styles from "../Styles/dailyScoop.module.css";
import VerticalCard from "../../Common/Components/VerticalCard";

const { Content } = Layout;
const { Title } = Typography;

const DailyScoop = ({ dailyScoops, related, alignment }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow:
      dailyScoops && dailyScoops.length >= 3
        ? 3
        : dailyScoops && dailyScoops.length >= 2
          ? 2
          : 1,
    centerMode: true,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    useCSS: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: dailyScoops && dailyScoops.length >= 2 ? 2 : 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: dailyScoops && dailyScoops.length >= 2 ? 2 : 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          fade: true,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <Content className={styles.dailyScoopContent}>
      <Row>
        <div className={styles.titleWrapper}>
          <Title className={styles.title}>
            {related ? "On The Cover" : "DAILY SCOOP"}
          </Title>
        </div>
        <div className={styles.divider}></div>
        <Col span={24}>
          {dailyScoops?.length === 0 || dailyScoops === undefined ? (
            <Empty
              style={{
                marginTop: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : (
            <Slider
              {...settings}
              style={{
                margin: "2%",
                padding: "15px",
              }}
            >
              {dailyScoops?.map((data) => (
                <React.Fragment key={uuidv4}>
                  <VerticalCard
                    image={
                      alignment === "poster"
                        ? data?.poster
                        : data?.thumbnail
                    }
                    title={data?.title}
                    tag={data?.tag === "sportsAndFitness" ? "Sports and Fitness" : data?.tag?.[0]?.toUpperCase() + data?.tag?.slice(1)}
                    path={data?.tag}
                    carousel={true}
                    textType={1}
                    id={data?.redirectURL}
                    onthecover={related === "On The Cover" && true}
                    type={related === "On The Cover" ? "magazine" : false}
                    magazin={related === "On The Cover" ? "true" : ""}
                  />
                </React.Fragment>
              ))}
            </Slider>
          )}
        </Col>
      </Row>
    </Content>
  );
};

export default DailyScoop;
