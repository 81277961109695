import { upTrendingConstants } from "./action-types";

const initialState = {
  newEntry: [],
  newEntryLoading: true,
  newEntryError: null,
  newEntryDesc: [],
  totalCount: 0,
};

const upTrendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case upTrendingConstants.GET_UP_TRENDING_SUCCESS:
      return {
        ...state,
        newEntry: action.upTrending.docs,
        newEntryLoading: true,
        newEntryError: null,
        totalCount: action.upTrending.totalDocs,
      };
    case upTrendingConstants.GET_UP_TRENDING_FAILURE:
      return {
        ...state,
        newEntry: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };
    case upTrendingConstants.GET_UP_TRENDING_BY_ID_SUCCESS:
      return {
        ...state,
        newEntryDesc: action.upTrending,
        newEntryLoading: true,
        newEntryError: null,
      };
    case upTrendingConstants.GET_UP_TRENDING_BY_ID_FAILURE:
      return {
        ...state,
        newEntryDesc: [],
        newEntryLoading: false,
        newEntryError: action.newEntryError,
      };
    default:
      return state;
  }
};

export { upTrendingReducer };
