import { descriptionListingConstants } from "./action-types";
import { commonApi } from "../../Common/utils/api";
import {bussinessDescription} from "../../Common/commondata"
/**
 * To update Navbar title action
 * @param {string}
 */



const descriptionLisitngError = (err) => {
  return {
    type:descriptionListingConstants.LISTING_DESCRIPTION_FAILURE,
    escriptionListingError:err
  }
}

const descriptionLisitngSuccess = (data,paramText) => {
 let title= paramText?.split("-")?.join(" ");
 let finalData = data?.filter(e=>e.title===title);
  return {
    type:descriptionListingConstants.LISTING_DESCRIPTION_SUCCESS,
    descriptionListing:finalData
  }
}

const getListingDescripton = (paramText) => {
  return (dispatch) => {
    commonApi.getDataRequest(paramText).then((response) => {
      dispatch(descriptionLisitngSuccess(bussinessDescription,paramText))
    })
    .catch((err) => {
      dispatch(descriptionLisitngError(err))
    })
  };
};

export const descriptionLisitngAction = {
  getListingDescripton,
};
