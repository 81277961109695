import { Spin } from 'antd'
import React from 'react'

const Spinner = () => {
  return (
    <div style={{display:"flex",justifyContent:'center',alignItems:'center',height:"90vh"}}>
      <Spin size='large' />
    </div>
  )
}

export default Spinner
