export const appconstants = {
    START_LOADING: "START_LOADING",
    STOP_LOADING: "STOP_LOADING",
    START_DAILY_SCOOP_LOADING: "START_DAILY_SCOOP_LOADING",
    STOP_DAILY_SCOOP_LOADING: "STOP_DAILY_SCOOP_LOADING",
    MESSAGE_SUCCESS: "MESSAGE_SUCCESS",
    MESSAGE_ERROR: "MESSAGE_ERROR",
    MESSAGE_ID_SUCCESS: "MESSAGE_ID_SUCCESS",
    MESSAGE_ID_ERROR: "MESSAGE_ID_ERROR",
    MESSAGE_MAG_SUCCESS: "MESSAGE_MAG_SUCCESS",
    MESSAGE_MAG_ERROR: "MESSAGE_MAG_ERROR",
    MESSAGE_CLEAR: "MESSAGE_CLEAR",

    START_LOADING_SEARCH: "START_LOADING_SEARCH",
    STOP_LOADING_SEARCH: "STOP_LOADING_SEARCH",
}