import { Route, Routes } from "react-router-dom";
import { Home } from "../Home";
import { ContactForm } from "../ContactForm/ContactForm";
import { FooterComp } from "../Footer/FooterComp";
import { Magazines, MagazinesArchive } from "../Magazines";
import { Disclaimer, PrivacyPolicy, TermsOfUse } from "../Static";
import { NotFound } from "../Common/Components/NotFound";
import { PrivateRoute } from "./Components/PrivateRoute";
import Login from "./Components/Login";
import { AboutUs } from "../AboutUs";
import { FloatButton, Layout } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { DescriptinListing } from "../DescriptinListing";
import AddContent from "../AddContent/AddContent";
import { MagazineListing } from "../MagazineListing";
import { Navbar } from "../Navbar";
import DailyScoop from "../DailyScop/DailyScoop";
import UpTrending from "../UpTrending/UpTrending";
import styles from "./Styles/App.module.css";
import Subscribe from "../Static/Subscribe";
import { CoverStories, WomenLeaders } from "../WomenLeaders";
import DailyScoopDescription from "../DailyScop/Components/DailyScoopDescription";
import { GuestPost, SponsoredPost } from "../ContactForm";
import UptrendingDescription from "../UpTrending/Components/UptrendingDescription";
import MagazineDescription from "../Magazines/MagazineDescription";
import DailyScoopByTag from "../DailyScop/Components/DailyScoopByTag";
import { Dashboard } from "../Dashboard/Dashboard";
import SpecialProfile from "../SpecialProfile/SpecialProfile";
import SpecialProfileDescription from "../SpecialProfile/Components/SpecialProfileDescription";
import DescriptionPage from "../Common/Components/DescriptionPage";

const { Footer, Content } = Layout;

function App() {
  return (
    <Layout className={styles.layoutStyle}>
      <Navbar />
      <Content className={styles.layoutStyle}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/listing/:id" element={<DescriptinListing />} />
          <Route exact path="/contactUs" element={<ContactForm />} />
          {/* <Route exact path="/magazine/:id" element={<MagazineListing />} /> */}
          <Route exact path="/daily-scoop" element={<DailyScoop />} />
          <Route exact path="/daily-scoop/:tag" element={<DailyScoopByTag />} />
          <Route
            exact
            path="/:id"
            element={<DescriptionPage />}
          />
          <Route
            exact
            path="/magazine/:id"
            element={<MagazineDescription />}
          />
          <Route
            exact
            path="/special-profile/:id"
            element={<SpecialProfileDescription />}
          />
          <Route exact path="/up-trending" element={<UpTrending />} />
          {/* <Route
            exact
            path="up-trending/:id"
            element={<UptrendingDescription />}
          /> */}
          <Route exact path="/guest-post" element={<GuestPost />} />
          <Route exact path="/sponsored-post" element={<SponsoredPost />} />
          <Route exact path="/special-profile" element={<SpecialProfile />} />

          <Route exact path="/magazines" element={<Magazines />} />
          <Route
            exact
            path="/magazine-archives"
            element={<MagazinesArchive />}
          />
          <Route exact path="/subscribe" element={<Subscribe />} />
          <Route exact path="/women-leaders" element={<WomenLeaders />} />
          <Route exact path="/cover-stories" element={<CoverStories />} />
          <Route exact path="/contactUs" element={<ContactForm />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-use" element={<TermsOfUse />} />
          {/* private routes */}
          <Route exact path="/dashboard" element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/dashboard/:type" element={<Dashboard />} />
            <Route
              exact
              path="/dashboard/addContent"
              element={<AddContent />}
            />
            <Route
              exact
              path="/dashboard/addContent/:subType"
              element={<AddContent />}
            />
            <Route
              exact
              path="/dashboard/addContent/:subType/:id"
              element={<AddContent />}
            />
          </Route>

          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Content>
      <div>
        <FloatButton.BackTop
          shape="square"
          rootClassName={styles.backto}
          style={{ backgroundColor: "#E34023", right: 20, bottom: 20 }}
          icon={<UpOutlined />}
        />
      </div>
      <Footer className={styles.footerStyle}>
        <FooterComp />
      </Footer>
    </Layout>
  );
}

export { App };
