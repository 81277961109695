import { homeConstants } from "./action-types";

const initialState = {
  homeData: [],
  homeDataLoading: true,
  homeDataError: null,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.GET_HOME_DATA_SUCCESS:
      return {
        ...state,
        homeData: action.homeData,
        homeDataLoading: true,
        homeDataError: null,
      };
    case homeConstants.GET_HOME_DATA_FAILURE:
      return {
        ...state,
        homeData: [],
        homeDataLoading: false,
        homeDataError: action.homeDataError,
      };

    default:
      return state;
  }
};
export { homeReducer };
