import axios from "axios";

// const apiURL = process.env.REACT_APP_BASE_URL;
const apiURL = process.env.REACT_APP_BACKEND_URL;

// get data by id, tag ==> source
const getDataRequest = (path, source) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // return axios.get(`${apiURL}/${path}/${source}`, config);
  return axios.get(`${apiURL}/${path}/${source}`, config);
};

// standered get api call
const getApiRequest = (path) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // return axios.get(`${apiURL}/${path}`, config);
  return axios.get(`${apiURL}/${path}`, config);
};

// Post data
const addDataRequest = (data, source, path) => {
  let token = localStorage.getItem("auth-token");

  const config = {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
      authorization: token,
    },
  };

  let sendData = { ...data};

  // return axios.post(`${apiURL}/${path}`, data, config);
  return axios.post(`${apiURL}/${path}/${source}`, sendData, config);
};

// put data
const putDataRequest = (data) => {
  // let accessTokan = localStorage.getItem("userAuth");
  let accessTokan = "Test";
  return axios.put(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const contactUsApi = {
  getApiRequest,
  getDataRequest,
  putDataRequest,
  addDataRequest,
};
