import { navbarConstants } from "./action-types";

const initialState = {
  searchData: [],
  searchQuery: "",
  searchDataLoading: true,
  searchDataError: null,
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case navbarConstants.GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searchData: action.searchData,
        searchDataLoading: true,
        searchDataError: null,
      };
    case navbarConstants.GET_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        searchData: [],
        searchDataLoading: false,
        searchDataError: action.searchDataError,
      };
    case navbarConstants.GET_SEARCH_QUERY_SUCCESS:
      return {
        ...state,
        searchQuery: action.searchData,
        searchDataLoading: true,
        searchDataError: null,
      };
    case navbarConstants.SEARCH_RESET:
      return {
        ...state,
        searchData: [],
        searchDataLoading: false,
        searchDataError: null,
      };
    default:
      return state;
  }
};
export { navbarReducer };
