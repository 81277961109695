import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { appAction } from "../state/actions";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../Styles/login.module.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

export default function Login() {
  const [seePassword, setSeePassword] = useState(true);
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const visibleChange = () => {
    setSeePassword(!seePassword);
  };
  const dispatch = useDispatch();
  const { LoginData } = useSelector((state) => state.appReducer);

  let token = localStorage.getItem("auth-token");

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [navigate, token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(appAction.loginEvent(formValues));
    navigate("/dashboard");
  };
  return LoginData ? (
    <Navigate to="/dashboard" />
  ) : (
    <div className={styles.container}>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          name="email"
          type="email"
          placeholder="Email"
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
          required
          className={styles.field}
        />
        <input
          name="password"
          type={seePassword ? "password" : "text"}
          placeholder="Password"
          onChange={(e) =>
            setFormValues({ ...formValues, password: e.target.value })
          }
          className={styles.field}
          required
        />
        {seePassword ? (
          <AiOutlineEyeInvisible
            onClick={visibleChange}
            className={styles.icon}
          />
        ) : (
          <AiOutlineEye
            onClick={visibleChange}
            className={`${styles.icon} ${styles.icon_visible}`}
          />
        )}
        <input type="submit" value="Login" className={styles.btn} />
      </form>
      <div className={styles.pass_link}>
        <button onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: "20px" }} />
        </button>
      </div>
    </div>
  );
}
export { Login };
