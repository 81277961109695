import { specialProfileConstants } from "./action-types";

const initialState = {
  specialdata: [],
  specialdataLoading: true,
  specialdataError: null,
  specialdataDesc: {},
  totalCountspecial: 0,
};

const specialReducer = (state = initialState, action) => {
  switch (action.type) {
    case specialProfileConstants.GET_SPECIAL_SUCCESS:
      return {
        ...state,
        specialdata: action.special.docs,
        specialdataLoading: true,
        specialdataError: null,
        totalCountspecial: action.special.totalDocs,
      };
    case specialProfileConstants.GET_SPECIAL_FAILURE:
      return {
        ...state,
        specialdata: [],
        specialdataLoading: false,
        specialdataError: action.newEntryError,
      };
    case specialProfileConstants.GET_SPECIAL_BY_ID_SUCCESS:
      return {
        ...state,
        specialdataDesc: action.special,
        specialdataLoading: true,
        specialdataError: null,
      };
    case specialProfileConstants.GET_SPECIAL_BY_ID_FAILURE:
      return {
        ...state,
        specialdataDesc: [],
        specialdataLoading: false,
        specialdataError: action.newEntryError,
      };
    default:
      return state;
  }
};

export { specialReducer };
