import React, { useEffect, useState } from "react";
import { Col, Empty, Layout, Pagination, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import styles from "./Styles/dailyScoop.module.css";
import { useDispatch, useSelector } from "react-redux";
import { dailyScoopAction } from "./state/actions";
import Spinner from "../Spinner/Spinner";
import VerticalCard from "../Common/Components/VerticalCard";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
import { constants } from "../Common/Components/Constants";
import { Helmet } from "react-helmet-async";
// import { Helmet } from "react-helmet-async";

const { Content } = Layout;
const { Title } = Typography;

const DailyScoop = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  let { newDailyScoopEntries, totalCount } = useSelector(
    (state) => state.dailyScoopReducer
  );

  const { isDailyscoopLoading, message } = useSelector(
    (str) => str.loadingReducer
  );

  useEffect(() => {
    dispatch(dailyScoopAction.getAllDailyScoopEvent("user", 1));
  }, [dispatch]);

  if (isDailyscoopLoading) {
    return <Spinner />;
  }
  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }

  const handlePageChange = (page) => {
    setPage(page);
    dispatch(dailyScoopAction.getAllDailyScoopEvent("user", page));
  };

  return (
    <Content style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta name="keywords" content={constants.dailyscoop.title}></meta>
        <title>{constants.dailyscoop.title}</title>
        <meta name="description" content={constants.dailyscoop.description} />

        <meta name="twitter:card" content="type" />
        <meta name="twitter:title" content={constants.dailyscoop.title} />
        <meta name="twitter:description" content={constants.dailyscoop.description} />

        <meta property="og:title" content={constants.dailyscoop.title} />
        <meta property="og:description" content={constants.dailyscoop.description} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`https://visionaryvogues.com/daily-scoop`} />
      </Helmet>
      <div className={styles.dailyScoopTitleWrapper}>
        <Title className={styles.dailyScoopTitle}>daily scoop</Title>
      </div>

      {newDailyScoopEntries?.length === 0 ||
        newDailyScoopEntries === undefined ? (
        <Empty
          style={{ marginTop: "100px" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row
          gutter={[16, 16]}
          style={{
            paddingTop: "40px",
            overflowX: "hidden",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {newDailyScoopEntries?.map((data) => {
            return (
              <Col xs={24} sm={12} md={8} lg={8} xl={6} key={uuidv4()}>
                <VerticalCard
                  image={data?.thumbnail}
                  title={data?.title}
                  tag={data?.tag === "sportsAndFitness" ? "SPORTS AND FITNESS" : data?.tag?.toUpperCase()}
                  path={data?.tag}
                  textType={1}
                  id={data?.redirectURL}
                />
              </Col>
            );
          })}
        </Row>
      )}
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "40px",
        }}
      >
        <Pagination
          defaultCurrent={1}
          total={totalCount}
          onChange={handlePageChange}
          current={page}
          showSizeChanger={false}
          pageSize={12}
          hideOnSinglePage={true}
        />
      </Row>
    </Content>
  );
};

export default DailyScoop;
