import { dashboardConstants } from "./action-types";
import { commonApi } from "../../Common/utils/api";
import { dashboardApi } from "../utils/api";
import { routesConstants } from "../../Common/utils/allroutes";
import {
  showMessageError,
  showMessageSuccess,
  startLoading,
  stopLoading,
} from "../../state/actions";

//Create content Event
const dashboardAddContentError = (err) => {
  return {
    type: dashboardConstants.CONTENT_CREATION_FAILURE,
    newEntryError: err,
  };
};

const dashboardAddContentSuccess = (data) => {
  return {
    type: dashboardConstants.CONTENT_CREATION_SUCCESS,
    newEntry: data,
  };
};

const dashboardAddContentEvent = (data, key) => {
  return (dispatch) => {
    dispatch(startLoading());
    const path = routesConstants[key].post;
    dashboardApi
      .addDataRequest(data, path)
      .then((response) => {
        dispatch(dashboardAddContentSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(dashboardAddContentError(err));
        dispatch(stopLoading());
      });
  };
};

// get data by tag
const dashboardGetContentError = (err) => {
  return {
    type: dashboardConstants.GET_BY_TAG_CONTENT_FAILURE,
    newEntryError: err,
  };
};

const dashboardGetContentSuccess = (data) => {
  return {
    type: dashboardConstants.GET_BY_TAG_CONTENT_SUCCESS,
    newEntry: data,
  };
};

const dashboardGetContentEvent = (tag, role, page, search) => {
  return (dispatch) => {
    let path;
    dispatch(startLoading());
    switch (tag) {
      case "upTrending": {
        path = routesConstants.upTrending.get;
        break;
      }
      case "magazine": {
        path = routesConstants.magazine.get;
        break;
      }
      case "inquiries": {
        path = routesConstants.contactUs.get;
        break;
      }
      case "specialProfile": {
        path = routesConstants.specialProfile.get;
        break;
      }
      default: {
        path = routesConstants.dailyScoops.getByTag;
        break;
      }
    }
    let apiCallPath =
      tag === "upTrending" ||
      tag === "magazine" ||
      tag === "inquiries" ||
      tag === "specialProfile"
        ? commonApi.getApiRequest(path, role, page, search)
        : commonApi.getDataRequest(path, tag, role, page, search);
    apiCallPath
      .then((response) => {
        dispatch(dashboardGetContentSuccess(response.data));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(dashboardGetContentError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err?.response));
      });
  };
};

//Get Content By Id
const getContentByIdError = (err) => {
  return {
    type: dashboardConstants.GET_CONTENT_BY_ID_FAILURE,
    newEntryError: err,
  };
};

const getContentByIdSuccess = (data) => {
  return {
    type: dashboardConstants.GET_CONTENT_BY_ID_SUCCESS,
    newEntry: data,
  };
};

const getContentById = (id, key, role) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants[key].getById;

    commonApi
      .getDataByTag(id, path, role)
      .then((response) => {
        dispatch(getContentByIdSuccess(response.data.info));
        dispatch(stopLoading());
        dispatch(showMessageSuccess("Fetched Successfully"));
      })
      .catch((err) => {
        dispatch(getContentByIdError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err.response));
      });
  };
};

// create uptrending
const createUpTrendingContentFailure = (err) => {
  return {
    type: dashboardConstants.UP_TRENDING_CREATION_FAILURE,
    newEntryError: err,
  };
};

const createUpTrendingContentSuccess = (data) => {
  return {
    type: dashboardConstants.UP_TRENDING_CREATION_SUCCESS,
    blog: data,
  };
};

const dashboardAddUpTrendingContentEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    commonApi
      .addDataRequest(data)
      .then((response) => {
        dispatch(createUpTrendingContentSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(createUpTrendingContentFailure(err));
        dispatch(stopLoading());
      });
  };
};

//update Event
const dashboardUpdateContentError = (err) => {
  return {
    type: dashboardConstants.CONTENT_UPDATE_FAILURE,
    newEntryError: err,
  };
};

const dashboardUpdateContentSuccess = (data, id) => {
  return {
    type: dashboardConstants.CONTENT_UPDATE_SUCCESS,
    newEntry: data,
    id: id,
  };
};

const dashboardUpdateContentEvent = (data, id, tag, pageNumber, daily) => {
  return (dispatch) => {
    let path;
    dispatch(startLoading());
    switch (tag) {
      case "upTrending": {
        path = routesConstants.upTrending.put;
        break;
      }
      case "magazine": {
        path = routesConstants.magazine.put;
        break;
      }
      case "inquiries": {
        path = routesConstants.contactUs.put;
        break;
      }
      case "specialProfile": {
        path = routesConstants.specialProfile.put;
        break;
      }
      default: {
        path = routesConstants.dailyScoops.put;
        break;
      }
    }

    if (tag === "magazine") delete data[tag];

    commonApi
      .updateDataByID(path, data, id)
      .then((response) => {
        !daily && dispatch(dashboardGetContentEvent(tag, "admin", pageNumber));
        dispatch(dashboardUpdateContentSuccess(response.data.info, id));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(dashboardUpdateContentError(err));
        dispatch(stopLoading());
      });
  };
};

// delete by ID
const deleteBlogByIDError = (err) => {
  return {
    type: dashboardConstants.DELETE_CONTENT_BY_ID_FAILURE,
    newEntryError: err,
  };
};

const deleteBlogByIDSuccess = (data) => {
  return {
    type: dashboardConstants.DELETE_CONTENT_BY_ID_SUCCESS,
    blog: data,
  };
};

const deleteContentEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    commonApi
      .deleteDataRequest(id)
      .then((response) => {
        dispatch(deleteBlogByIDSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(deleteBlogByIDError(err));
        dispatch(stopLoading());
      });
  };
};

export const clearNewEntry = () => ({
  type: dashboardConstants.CLEAR_NEWENTRY,
});
export const clearError = () => ({
  type: dashboardConstants.CLEAR_ERROR,
});

export const dashboardAction = {
  getContentById,
  dashboardAddContentEvent,
  dashboardUpdateContentEvent,
  dashboardGetContentEvent,
  deleteContentEvent,
  dashboardAddUpTrendingContentEvent,
};
