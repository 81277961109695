import React, { useState } from "react";
import { Col, Layout, Modal, Row, Typography, message } from "antd";
import { v4 as uuid4 } from "uuid";
import { FaDollarSign } from "react-icons/fa6";
import subscribeImage from "../Assets/static/subscribe.png";
import { CheckOutlined } from "@ant-design/icons";
import styles from "./Styles/subscribe.module.css";
import { contactUsAction } from "../ContactForm/state/actions";
import { useDispatch } from "react-redux";
import SubscriptionForm from "./SubscriptionForm";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Title, Text } = Typography;
const { Content } = Layout;

const subscribeData = [
  {
    title: "monthly",
    price: "999",
    content: [
      "Access to premium content",
      "Subscriber - only Newsletters.",
      "No Ads and Unlimited Access.",
    ],
  },
  {
    title: "quartely",
    price: "2999",
    content: [
      "Access to premium content",
      "Subscriber - only Newsletters.",
      "No Ads and Unlimited Access.",
    ],
  },
  {
    title: "yearly",
    price: "4999",
    content: [
      "Access to premium content",
      "Subscriber - only Newsletters.",
      "No Ads and Unlimited Access.",
    ],
  },
];


const Subscribe = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const handleSubmit = (e, values, setValues, source) => {
    e.preventDefault();
    dispatch(contactUsAction.AddContactUsModalData(values, source));

    setValues({
      name: "",
      email: "",
      website: "",
      message: "",
    });
    setIsModalOpen(false);

    messageApi.open({
      type: "success",
      content: "Subscription sent!!",
    });
  };
  return (
    <Content>
      <Helmet>
        <meta name="keywords" content={constants.subscribe.title}></meta>
        <title>{constants.subscribe.title}</title>
        <meta name="description" content={constants.subscribe.description} />
        <meta property="og:title" content={constants.subscribe.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/subscribe`}
        />
      </Helmet>
      {contextHolder}
      <section>
        <img
          src={subscribeImage}
          alt="Privacy_policy"
          className={styles.image}
        />
        <div className={styles.overlay}>
          <Title
            style={{ display: "block", marginBottom: "0" }}
            className={styles.title}
          >
            Subscribe
          </Title>
          <Text style={{ display: "block" }} className={styles.text}>
            To our weekly newsletter to tap into the world of amazing business
            insights. We have different plans for different requirements so that
            you can choose what works best for you. Here are our plans:
          </Text>
        </div>
      </section>

      <section className={styles.cardWrapper}>
        <Row
          gutter={[
            { xs: 16, sm: 16, md: 16, lg: 32 },
            { xs: 16, sm: 16, md: 16, lg: 32 },
          ]}
          justify={"center"}
        >
          {subscribeData?.map((data) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={7} xxl={8} key={uuid4()}>
              <div className={styles.card}>
                <div className={styles.headingWrapper}>
                  <Text style={{ display: "block" }} className={styles.heading}>
                    {data?.title}
                  </Text>
                </div>

                <div className={styles.currencyWrapper}>
                  <FaDollarSign className={styles.currencyIcon} />
                  &nbsp; <span className={styles.currency}>{data?.price}</span>
                </div>

                <div className={styles.checklistWrapper}>
                  {data?.content?.map((content) => (
                    <div key={uuid4()}>
                      <CheckOutlined className={styles.checkIcon} />
                      &nbsp; <span className={styles.checkText}>{content}</span>
                    </div>
                  ))}
                </div>

                <div className={styles.buttonWrapper} onClick={showModal} >
                  <button type="submit">
                    SUBSCRIBE NOW
                  </button>
                </div>
                <Modal
                  closeIcon={true}
                  open={isModalOpen}
                  footer={null}
                  centered
                  onCancel={handleCancel}
                >
                  <SubscriptionForm handleSubmit={handleSubmit} />
                </Modal>
              </div>
            </Col>
          ))}
        </Row>
      </section>
    </Content>
  );
};
export default Subscribe;