import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import { Divider, Typography, Layout } from "antd";
import { FlipBook } from "../FlipBook/FlipBook";
import { Helmet } from "react-helmet-async";
import VerticalCard from "../Common/Components/VerticalCard";
import styles from "./Styles/Magazine.module.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { magazineAction } from "../Magazines/state/actions";
import Spinner from "../Spinner/Spinner";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
const { Content } = Layout;
const { Title } = Typography;

const MagazineListing = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // const { magazinePDF } = useSelector((state) => state.magazineReducer);
  const { article } = useSelector((str) => str.commonReducer);
  // useEffect(() => {
  //   dispatch(magazineAction.getMagazineByIdModalData(params.id, "user"));
  //   dispatch(magazineAction.getMagazineModalData("user", 1));
  // }, [dispatch, params.id]);
  const { magazineData } = useSelector((state) => state.magazineReducer);
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow:
      magazineData && magazineData.length >= 4
        ? 4
        : magazineData && magazineData.length >= 3
        ? 3
        : magazineData && magazineData.length >= 2
        ? 2
        : 1,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    focusOnChange: true,
    arrows: false,
    useCSS: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: "40px",
          slidesToShow: magazineData && magazineData.length >= 3 ? 3 : 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: magazineData && magazineData.length >= 2 ? 2 : 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: magazineData && magazineData.length >= 2 ? 2 : 1,
        },
      },
    ],
  };
  const { isLoading, message } = useSelector((str) => str.loadingReducer);
  if (isLoading) {
    return <Spinner />;
  }
  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }
  return (
    <div style={{ margin: "30px" }}>
      <Helmet>
        <title>{article?.title}</title>
        <meta name="keywords" content={article?.keywords}></meta>
        <meta name="description" content={article?.description} />

        <meta name="twitter:card" content={"type"} />
        <meta name="twitter:title" content={article?.title} />
        <meta name="twitter:description" content={article?.description} />

        <meta property="og:image" content={article?.thumbnail} />
        <meta property="og:description" content={article?.description} />
        <meta property="og:title" content={article?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/${article?.redirectURL}`}
        />

        {/* <link
          rel="canonical"
          href={`https://visionaryvogues.com/magazine/${article._id}`}
        /> */}
      </Helmet>
      <Title className={styles.title}>{article?.title}</Title>
      <Divider style={{ backgroundColor: "white" }} />
      <FlipBook pdf={article?.pdfURL} />
      <Divider />
      <Content>
        <Typography.Title style={{ color: "#00569E" }}>
          RELATED MAGAZINES
        </Typography.Title>
        <Slider {...settings}>
          {magazineData?.map((magazine) => (
            <VerticalCard
              key={uuidv4()}
              image={magazine?.thumbnail}
              title={magazine?.title}
              type={"magazine"}
              id={magazine?.redirectURL}
              magazin={"true"}
            />
          ))}
        </Slider>
      </Content>
    </div>
  );
};

export { MagazineListing };
