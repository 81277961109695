import { appconstants } from "./action-types";
const initialState = {
  isLoading: false,
  isDailyscoopLoading: false,
  message: "",
  messageID: "",
  magmessage: "",
  isLoadingSearch: false,
};
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case appconstants.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case appconstants.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case appconstants.START_DAILY_SCOOP_LOADING:
      return {
        ...state,
        isDailyscoopLoading: true,
      };
    case appconstants.STOP_DAILY_SCOOP_LOADING:
      return {
        ...state,
        isDailyscoopLoading: false,
      };
    case appconstants.MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.message,
      };
    case appconstants.MESSAGE_ERROR:
      return {
        ...state,
        message: action.message,
      };
    case appconstants.MESSAGE_MAG_SUCCESS:
      return {
        ...state,
        magmessage: action.message,
      };
    case appconstants.MESSAGE_MAG_ERROR:
      return {
        ...state,
        magmessage: action.message,
      };
    case appconstants.MESSAGE_ID_SUCCESS:
      return {
        ...state,
        messageID: action.message,
      };
    case appconstants.MESSAGE_ID_ERROR:
      return {
        ...state,
        messageID: action.message,
      };
    case appconstants.MESSAGE_CLEAR:
      return {
        ...state,
        message: ""
      }
    case appconstants.START_LOADING_SEARCH:
      return {
        ...state,
        isLoadingSearch: true,
      };
    case appconstants.STOP_LOADING_SEARCH:
      return {
        ...state,
        isLoadingSearch: false,
      };
    default:
      return state;
  }
};

export { loadingReducer };
