import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import privacyPolicyImage from "../Assets/static/privacyPolicy.png";
import styles from "./Styles/static.module.css";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";
const { Title, Text } = Typography;

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content={constants.privacyPolicy.title}></meta>
        <title>{constants.privacyPolicy.title}</title>
        <meta
          name="description"
          content={constants.privacyPolicy.description}
        />
        <meta property="og:title" content={constants.privacyPolicy.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/privacy-policy`}
        />
      </Helmet>
      <section>
        <img
          src={privacyPolicyImage}
          alt="Privacy_policy"
          className={styles.image}
        />
        <div className={styles.overlay}>
          <Text style={{ display: "block" }} className={styles.text}>
            Protecting your data
          </Text>
          <Text style={{ display: "block" }} className={styles.text}>
            with transparency, integrity, and strict confidentiality measures.
          </Text>
        </div>
      </section>

      <div className={styles.container}>
        {/* <Title className={styles.title}>Privacy Policy</Title> */}
        <Title className={styles.privacy_Policy}>
          Visionary Vogues operates &nbsp;
          <Link
            href="https://www.visionaryvogues.com"
            style={{ color: "black" }}
          >
            www.visionaryvogues.com
          </Link>
          .
        </Title>
        <Text style={{ display: "block" }} className={styles.text}>
          If you have any queries regarding the site or content that is present
          on site, you may directly contact us at info@visionaryvogues.com. We
          are a dynamic media platform, and try to explore different business
          fields such as IT, Healthcare, Manufacturing, Finance, Education, etc.
        </Text>
        <Title className={styles.privacy_Policy}>
          Personal Identification Information
        </Title>
        <Text className={styles.text}>
          We may collect PII from Users in different ways, including, but not
          limited to, when Users visit our site, subscribe to the newsletter,
          fill out a form, and in connection with other activities, services,
          features or resources we make available on our Site. Users may be
          asked for name, email address, mailing address while completing one of
          these activities. Users may, however, visit our Site anonymously.
        </Text>
        <br />
        <Text className={styles.text}>
          We will collect personal identification information from Users only if
          they intentionally submit such information to us. Users can always
          refuse to supply personally identification information, which can stop
          them from interacting with certain Site related activities.
        </Text>
        <Title className={styles.privacy_Policy}>
          Data Collection and Usage
        </Title>
        <Text className={styles.text}>
          We only use information which is available on internet platforms (Work
          Email, Office Contact Number), when approaching website visitor
          through e-mails. It is collected through our website or other
          communication channels such as email or phone. User can decide to opt
          out from our emails at any time.
        </Text>
        <Title className={styles.privacy_Policy}>Who Collects this data?</Title>
        <Text className={styles.text}>
          The data is solely collected by Visionary Vogues from the information
          that is available on public internet domains. We do not use any third
          parties to collect data.
        </Text>
        <Title className={styles.privacy_Policy}>Will my Data be shared?</Title>
        <Text className={styles.text}>
          We do not sell, trade, or rent Users’ personal identification
          information to others. If any incidences arise, where there is a need
          to share information/ data, it will only be done with visitor’s
          explicit permission and only for as long as is necessary.
          <br />
        </Text>
        <Text className={styles.text}>
          We use third party applications for our day to day business
          operations. Below is a list of such tools, you can go through their
          GDPR Compliance Statements.
        </Text>
        <ul>
          <li className={styles.text}>
            The Website Uses fully encrypted SSL Certificate for data protection
          </li>
          <li className={styles.text}>
            Google Drive and different formats under it such as Google Sheets,
            Google Docs are used for daily business operations.
          </li>
        </ul>
        <Title className={styles.privacy_Policy}>
          What Rights Does the Visitor Have?
        </Title>
        <Text className={styles.text}>
          Visitors have the right to view his information we hold. If one wishes
          to see so, please contact us at info@enterprisewired.com
        </Text>
        <Title className={styles.privacy_Policy}>Changes Notification</Title>
        <Text className={styles.text}>
          We review our security measures and Privacy Policy on a periodic
          basis, and think to update the same from time to time. We may also
          change or update our Privacy Policy if we add new products; services
          or sections and the same will be posted here.
        </Text>
      </div>
    </>
  );
};

export { PrivacyPolicy };
