import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ScrollToTop } from "./Common/Components/ScrollToTop";
import { Provider } from "react-redux";
import { store } from "./store";
import { ConfigProvider } from "antd";
import './index.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      components: {
        Form: {
          labelRequiredMarkColor: "red",
          fontFamily: "Roboto,sans-serif",
        },
        FloatButton: {
          colorBgElevated: "black",
          colorText: "white",
          controlItemBgHover: "#E34023",
          fontSizeIcon: 8,
          controlHeightLG: 25,
          borderRadiusLG: 2,
        },
        Menu: {
          horizontalItemSelectedColor: "none",
          itemSelectedColor: "white",
          itemHoverColor: "none",
          colorSplit: "#dcdcdc",
          itemColor: "#fff",
          colorBgElevated: "black",
          itemSelectedBg: "black",
        },
        Layout: {
          paddingContentHorizontalLG: 0,
        },
        Button: {
          paddingBlockLG: 9,
          paddingInline: 21,
        },
      },
      token: {
        fontFamily: "Montserrat,sans-serif",
        colorPrimary: "#005293",
      },
    }}
  >
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </ConfigProvider>
);
