import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Dropdown,
  Image,
  Layout,
  List,
  Menu,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  CloseOutlined,
  DashboardOutlined,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import whiteLogo from "../Assets/Logo/whiteLogo.png";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { appAction } from "../App/state/actions";
import styles from "./Styles/header.module.css";
import logoStyles from "./Styles/logo.module.css";
import { navbarAction } from "./state/actions";
import { SearchComponent } from "./Components/SearchComponent";

const { Header } = Layout;
const { Text } = Typography;

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState("home");
  const handleClick = (e) => {
    setActiveKey(e.key);
  };
  const { searchData } = useSelector((state) => state.navbarReducer);
  const isMobile = useMediaQuery({ maxWidth: 876 });
  const isLargescreen = useMediaQuery({ maxWidth: 992 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { isLoadingSearch } = useSelector((str) => str.loadingReducer);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const token = localStorage.getItem("auth-token");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const onClickEvent = (e) => {
    dispatch(appAction.logoutEvent(false));
    localStorage.removeItem("auth-token");
    navigate("/");
  };

  useEffect(() => {
    const pathname = location.pathname.split("/")[1];
    if (pathname === "") setActiveKey("home");
    else setActiveKey(pathname);
  }, [location.pathname]);

  const handleCancel = () => {
    let searchElement = document.getElementById("searchInput");
    searchElement.value = "";
    dispatch(navbarAction.searchReset());
    setIsModalOpen(false);
  };

  const handleclickpath = (item, type) => {
    handleCancel();
    // console.log(item, "item")
    if (type === "admin") {
      navigate(`${item?.adminpath}`, { state: { search: item.redirectURL } });
    } else if (type === "user") {
      navigate(item?.path);
    }
  };

  const menuData = [
    {
      label: (
        <Link
          to="/"
          style={{ paddingInline: "5px" }}
          className={
            activeKey === "home"
              ? `${styles.Avatar}`
              : `${styles.normalPadding}`
          }
        >
          Home
        </Link>
      ),
      key: "home",
    },
    // { type: "divider", dashed: true },
    {
      label: (
        <Link
          to="/magazines"
          style={{ paddingInline: "5px" }}
          className={
            activeKey === "magazines"
              ? `${styles.Avatar}`
              : `${styles.normalPadding}`
          }
        >
          Magazines
        </Link>
      ),
      key: "magazines",
    },
    // { type: "divider", dashed: true },
    {
      label: (
        <Link
          to="/daily-scoop"
          style={{ paddingInline: "5px" }}
          className={
            activeKey === "daily-scoop"
              ? `${styles.Avatar}`
              : `${styles.normalPadding}`
          }
          rel="noopener noreferrer"
        >
          Daily Scoop
        </Link>
      ),
      key: "daily-scoop",
    },
    // { type: "divider", dashed: true },
    {
      label: (
        <Link
          to="/up-trending"
          style={{ paddingInline: "5px" }}
          className={
            activeKey === "up-trending"
              ? `${styles.Avatar}`
              : `${styles.normalPadding}`
          }
        >
          Up-Trending
        </Link>
      ),
      key: "up-trending",
    },
    {
      key: "search",
      icon: (
        <SearchOutlined
          size={20}
          className={styles.searchMenu}
          style={{ display: isMobile ? "none" : "inline-block" }}
          onClick={showModal}
        />
      ),
      disabled: false,
    },
  ];

  const profileItems = [
    {
      key: "1",
      label: <Link to="/dashboard">Dashboard</Link>,
      icon: (
        <Link to="/dashboard">
          <DashboardOutlined />
        </Link>
      ),
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: onClickEvent,
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
  };

  // console.log(isLoadingSearch, "isLoadingSearch");

  return (
    <div className={styles.navbarWrapper}>
      <Header className={styles.headerStyle} style={{ paddingLeft: "none" }}>
        {/* Logo */}
        <div className={logoStyles.LogoAlignment}>
          <Link to="/">
            <img
              src={whiteLogo}
              className={logoStyles.visionarylogo}
              alt="Logo"
            />
            {/* <Text className={logoStyles.visionarylogo}>Visionary</Text> */}
          </Link>
        </div>

        {/* Search bar */}
        {isMobile && (
          <SearchOutlined
            className={`${styles.search}`}
            onClick={() => showModal()}
            style={{ marginLeft: "15px" }}
          />
        )}
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          maskClosable={true}
          bodyStyle={{
            height: "400px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <SearchComponent />
          {isLoadingSearch ?
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div> :
            <List
              dataSource={searchData}
              renderItem={(item) => (
                <div
                  style={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <Row
                    gutter={[8, 8]}
                    style={{
                      // borderBottom: "1px solid black",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      span={16}
                      style={{
                        cursor: "pointer",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    // onClick={() => handleclickpath(item?.path)}
                    >
                      <div onClick={() => handleclickpath(item, "user")}>
                        <List.Item style={{ padding: 0 }}>
                          <Tag color="rgb(26,104,161)">
                            {item?.tag?.toUpperCase()}
                          </Tag>
                        </List.Item>
                        <List.Item style={{ padding: 0 }}>
                          <Typography.Paragraph
                            ellipsis={{ rows: 2, symbol: "..." }}
                            style={{ fontSize: "16px" }}
                          >
                            {item?.title}
                          </Typography.Paragraph>
                        </List.Item>
                      </div>
                      {token && (
                        <List.Item style={{ padding: 0, paddingBottom: "1.2em" }}>
                          <Button
                            size="small"
                            type="link"
                            style={{ width: "fit-content" }}
                            onClick={() => handleclickpath(item, "admin")}
                          >
                            Go to dashboard
                          </Button>
                        </List.Item>
                      )}
                    </Col>
                    <Col span={6}>
                      <List.Item>
                        <div
                          style={{
                            height: "70px",
                            width: "70px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            src={item.thumbnail}
                            width={"100%"}
                            height={"auto"}
                            preview={false}
                          />
                        </div>
                      </List.Item>
                    </Col>
                  </Row>
                  {/* <Row justify={"center"}>
                  <Button
                    size="small"
                    type="primary"
                    style={{ width: "fit-content" }}
                  >
                    Go to dashboard
                  </Button>
                </Row> */}
                </div>
              )}
            />}
        </Modal>

        {/* Menu */}
        <Menu
          theme="light"
          mode="horizontal"
          className={styles.menu}
          onClick={(e) => handleClick(e)}
          defaultSelectedKeys={[activeKey]}
          activeKey={[activeKey]}
          items={menuData}
          overflowedIndicator={<MenuOutlined size={"large"} />}
          style={{
            flex: 1,
            minWidth: 0,
            fontSize: "larger",
            paddingInline: "5px",
          }}
        />
        {!isLargescreen && (
          <MenuOutlined
            style={{
              color: "white",
              paddingRight: "25px",
              fontSize: "25px",
              fontWeight: "800",
            }}
            onClick={showDrawer}
          />
        )}
        <Drawer
          placement="right"
          onClose={onClose}
          open={open}
          closeIcon={false}
          bodyStyle={{ backgroundColor: "#052c50" }}
          headerStyle={{ backgroundColor: "#052c50" }}
          extra={
            <Space>
              <div
                onClick={onClose}
                style={{
                  color: "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  fontWeight: "bolder",
                }}
              >
                <CloseOutlined />
              </div>
            </Space>
          }
        >
          <Link to={"/aboutUs"} onClick={onClose}>
            <Text className={styles.drawertext}>About</Text>
          </Link>
          <Link to={"/cover-stories"} onClick={onClose}>
            <Text className={styles.drawertext}>Cover Stories</Text>
          </Link>
          <Link to={"/women-leaders"} onClick={onClose}>
            <Text className={styles.drawertext}>Women leaders</Text>
          </Link>
          <Link to={"/special-profile"} onClick={onClose}>
            <Text className={styles.drawertext}>Special Profile</Text>
          </Link>
          <Link to={"/sponsored-post"} onClick={onClose}>
            <Text className={styles.drawertext}>Submit Guest Post</Text>
          </Link>
          <Link to={"/contactUs"} onClick={onClose}>
            <Text className={styles.drawertext}>Contact</Text>
          </Link>
          <Link to={"/subscribe"} onClick={onClose}>
            <Text className={styles.drawertext}>Subscribe</Text>
          </Link>
        </Drawer>

        {!isMobile && (
          <Row gutter={[8, 12]} align={"bottom"} justify={"center"}>
            {token && (
              <Col span={24}>
                <Dropdown menu={{ items: profileItems }}>
                  <Avatar
                    style={{
                      cursor: "pointer",
                    }}
                    size="large"
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    icon={<UserOutlined className={`${styles.profile}`} />}
                  />
                </Dropdown>
              </Col>
            )}
          </Row>
        )}
      </Header>
    </div>
  );
}
export { Navbar };
