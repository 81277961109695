import React from "react";
import { useSelector } from "react-redux";
import { Button, Col, Input, Row, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

function TableComponentDetails({
  blogMagazineColumns,
  dataSource,
  totalCount,
  fetchRecords,
  activePage,
  search
}) {
  let { isLoading } = useSelector((state) => state.loadingReducer);
  const location = useLocation();
  const navigate = useNavigate();

  const tableProps = {
    loading: isLoading,
    columns: blogMagazineColumns,
    dataSource: dataSource,
    bordered: true,
    pagination: {
      total: totalCount,
      hideOnSinglePage: true,
      position: ["bottomCenter"],
      showSizeChanger: false,
      pageSize: 5,
      current: activePage,
      onChange: (page) => {
        fetchRecords(page);
      },
    },
    scroll: {
      y: "70vh",
    },
  };

  return (
    <div>
      {search ? (
        <Table
          {...tableProps}
          title={() => (
            <Row style={{ alignItems: 'center' }}>
              <Col span={12}>
                <Button
                  style={{ width: "fit-content" }}
                  type="primary"
                  onClick={() => {
                    // const params = new URLSearchParams(location.search);
                    // console.log(params.delete("search"), "params")
                    // params.delete("search");
                    // navigate(`${location.pathname}?${params.toString()}`);

                    const { search, ...restState } = location.state || {};

                    // If you're also managing search in query params
                    const params = new URLSearchParams(location.search);
                    params.delete("search");

                    // Navigate to the same path without the "search" in state and query params
                    navigate(`${location.pathname}?${params.toString()}`, { state: restState });
                  }} // Adjust this based on your clear search logic
                >
                  Clear search
                </Button>
              </Col>
            </Row>
          )}
        />
      ) : (
        <Table {...tableProps} />
      )}
    </div>
  );
}
export { TableComponentDetails };
