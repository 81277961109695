import { magazineConstants } from "./action-types";

const initialState = {
  magazineData: [],
  magazinePDF: [],
  magazineYear: [],
  magazineDataLoading: true,
  magazineDataError: null,
  totalCount: 0,
};

const magazineReducer = (state = initialState, action) => {
  switch (action.type) {
    case magazineConstants.GET_MAGAZINE_DATA_SUCCESS:
      return {
        ...state,
        magazineData: action.magazineData.docs,
        magazineDataLoading: true,
        magazineDataError: null,
        totalCount: action.magazineData.totalDocs,
      };
    case magazineConstants.GET_MAGAZINE_DATA_FAILURE:
      return {
        ...state,
        magazineData: [],
        magazineDataLoading: false,
        magazineDataError: action.homeDataError,
      };
    case magazineConstants.GET_MAGAZINE_ARCHIVE_DATA_SUCCESS:
      return {
        ...state,
        magazineData: action.magazineData,
        magazineDataLoading: true,
        magazineDataError: null,
      };
    case magazineConstants.GET_MAGAZINE_ARCHIVE_DATA_FAILURE:
      return {
        ...state,
        magazineData: [],
        magazineDataLoading: false,
        magazineDataError: action.homeDataError,
      };
    case magazineConstants.GET_MAGAZINE_DATA_BY_YEAR_SUCCESS:
      return {
        ...state,
        magazineYear: action.magazineData,
        magazineDataLoading: true,
        magazineDataError: null,
      };
    case magazineConstants.GET_MAGAZINE_DATA_BY_YEAR_FAILURE:
      return {
        ...state,
        magazineYear: [],
        magazineDataLoading: false,
        magazineDataError: action.homeDataError,
      };
    case magazineConstants.GET_MAGAZINE_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        magazinePDF: action.magazineData,
        magazineDataLoading: true,
        magazineDataError: null,
      };
    case magazineConstants.GET_MAGAZINE_DATA_BY_ID_FAILURE:
      return {
        ...state,
        magazineData: [],
        magazineDataLoading: false,
        magazineDataError: action.homeDataError,
      };

    default:
      return state;
  }
};
export { magazineReducer };
