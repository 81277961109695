export const routesConstants = {
  getHomeData: "/d4867d8b-b5d5-4a48-a4ab-79131b5809b8",
  dailyScoops: {
    get: "dailyScoop",
    getById: "dailyScoop",
    getByTag: "dailyScoops",
    getAllDailyScoop: "allDailyScoop",
    post: "addDailyScoop",
    deleteById: "dailyScoop",
    put: "dailyScoop",
  },
  upTrending: {
    get: `allUpTrendings`,
    getById: "upTrending",
    post: "addUpTrending",
    deleteById: "upTrending",
    put: "upTrending",
  },
  specialProfile: {
    get: `allSpecialProfiles`,
    getById: "specialProfile",
    post: "addSpecialProfile",
    deleteById: "specialProfile",
    put: "specialProfile",
  },
  magazine: {
    get: "allMagazines",
    getById: "magazine",
    getByYear: "magazines",
    archive: "magazine-archives",
    post: "addMagazine",
    deleteById: "addMagazine",
    put: "magazine",
  },
  contactUs: {
    post: "addContact",
    get: "allContacts",
    getById: "contact",
    put: "contact",
    deleteById: "contact",
  },
  home: {
    get: "home",
  },
  womenLeaderAndCoverStories: {
    getWomenLeaders: "allWomenLeaders",
    getCoverStories: "allCoverStories",
  },
  search: {
    get: "search",
  },
};
