import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { descriptionLisitngAction } from "./state/actions";
import { Typography, Layout, Divider, Image, Row, Col } from "antd";
import styles from "./Styles/DescriptionListing.module.css";
import { SubscribeSection } from "../Common";
import VerticalCard from "../Common/Components/VerticalCard";
const { Title, Text } = Typography;
const { Content } = Layout;

function DescriptinListing() {
  const dispatch = useDispatch();
  const paramText = useParams();
  useEffect(() => {
    dispatch(descriptionLisitngAction.getListingDescripton(paramText?.id));
  }, [dispatch, paramText]);

  const { descriptionListing } = useSelector(
    (state) => state.descriptionListingReducer
  );
  return (
    <div style={{ margin: "20px" }}>
      <Content className={styles.container}>
        <Text className={styles.category}>
          {descriptionListing[0]?.category}
        </Text>
        <Title className={styles.title}>{descriptionListing[0]?.title}</Title>
        <Divider style={{ backgroundColor: "black" }} />
      </Content>
      {/* 2 columns */}
      <Row gutter={[16, 16]}>
        <Col md={24} lg={18}>
          <Image src={descriptionListing[0]?.image} preview={false} />
          <Content>
            <Text className={styles.description}>
              {descriptionListing[0]?.description}
            </Text>
            {descriptionListing[0]?.subsections?.map((section) => (
              <div key={uuidv4()}>
                <Title className={styles.title1}>
                  {section?.subsectionTitle1}
                </Title>
                {section?.subsection1?.map((e) => (
                  <div key={uuidv4()}>
                    <Title className={styles.heading}>{e?.heading}</Title>
                    <Image src={e?.image} preview={false} />
                    <br />
                    <Text className={styles.description}>{e?.desc}</Text>
                  </div>
                ))}
              </div>
            ))}
            <Title className={styles.title1}>Conclusion</Title>
            <Text className={styles.description}>
              {descriptionListing[0]?.conclusion}
            </Text>
          </Content>
        </Col>
        <Col md={24} lg={6} className={styles.stickyCol}>
          <SubscribeSection />
        </Col>
      </Row>
      {/* related articles */}
      <Title style={{ textAlign: "center" }} className={styles.title}>
        RELATED ARTICLES
      </Title>
      <Divider style={{ backgroundColor: "black" }} />
      <Row gutter={[16, 16]}>
        {descriptionListing[0]?.articles?.map((article) => (
          <Col xs={24} sm={12} md={12} lg={6} key={uuidv4()}>
            <VerticalCard
              isImage={true}
              image={article?.image}
              title={article?.title}
              description={article?.description}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export { DescriptinListing };
