import { womenLeadersCoverStoriesConstants } from "./action-types";
import { womenLeadersAndCoverStoriesApi } from "../utils/api";
import { routesConstants } from "../../Common/utils/allroutes";
import { startLoading, stopLoading } from "../../state/actions";

/**
 * To update Navbar title action
 * @param {string}
 */

const getWomenLeadersModalError = (err) => {
  return {
    type: womenLeadersCoverStoriesConstants.GET_WOMEN_LEADERS_DATA_FAILURE,
    WomenLeaderAndCoverError: err,
  };
};

const getWomenLeadersModalSuccess = (data) => {
  return {
    type: womenLeadersCoverStoriesConstants.GET_WOMEN_LEADERS_DATA_SUCCESS,
    WomenLeaderAndCoverData: data,
  };
};

const getWomenLeadersModalData = (page) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.womenLeaderAndCoverStories.getWomenLeaders;
    womenLeadersAndCoverStoriesApi
      .getDataRequest(path, page)
      .then((response) => {
        dispatch(getWomenLeadersModalSuccess(response.data));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getWomenLeadersModalError(err));
        dispatch(stopLoading());
      });
  };
};

const getCoverStoriesModalError = (err) => {
  return {
    type: womenLeadersCoverStoriesConstants.GET_COVER_STORIES_DATA_FAILURE,
    WomenLeaderAndCoverError: err,
  };
};

const getCoverStoriesModalSuccess = (data) => {
  return {
    type: womenLeadersCoverStoriesConstants.GET_COVER_STORIES_DATA_SUCCESS,
    WomenLeaderAndCoverData: data,
  };
};

const getCoverStoriesModalData = (page) => {
  return (dispatch) => {
    dispatch(startLoading());
    let path = routesConstants.womenLeaderAndCoverStories.getCoverStories;
    womenLeadersAndCoverStoriesApi
      .getDataRequest(path, page)
      .then((response) => {
        dispatch(getCoverStoriesModalSuccess(response.data));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getCoverStoriesModalError(err));
        dispatch(stopLoading());
      });
  };
};

export const womenLeaderAndCoverStoriesAction = {
  getWomenLeadersModalData,
  getCoverStoriesModalData,
};
