import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import styles from "./Styles/subscribe.module.css";
const { TextArea } = Input;

const SubscriptionForm = ({ handleSubmit }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    website: "",
    message: "",
  });
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          style={{ width: "100%" }}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          <Form layout="vertical">
            <Form.Item
              wrapperCol={24}
              style={{ cursor: "pointer" }}
              required
              rules={[{ required: true }]}
            >
              <Input
                className={styles.formInput}
                placeholder="Your name"
                type="text"
                name="name"
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                value={values.name}
              />
            </Form.Item>
            <Form.Item
              required
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                name="email"
                placeholder="@email.com"
                className={styles.formInput}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                value={values.email}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
                {
                  type: "url",
                  message: "This field must be a valid url.",
                },
              ]}
            >
              <Input
                name="website"
                className={styles.formInput}
                placeholder="Website"
                onChange={(e) =>
                  setValues({ ...values, website: e.target.value })
                }
                value={values.website}
              />
            </Form.Item>
            <Form.Item rules={[{ required: true }]}>
              <TextArea
                rows={4}
                className={styles.formInput}
                placeholder="Message"
                name="message"
                onChange={(e) =>
                  setValues({ ...values, message: e.target.value })
                }
                value={values.message}
              />
            </Form.Item>
          </Form>

          <div className={styles.buttonWrapper}>
            <button
              type="submit"
              onClick={(e) => handleSubmit(e, values,setValues, "subscribe")}
            >
              Submit
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionForm;
