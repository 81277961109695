import { loginConstants } from "./action-types";
import { commonApi } from "../../Common/utils/api";
import { showMessageError, showMessageSuccess, startLoading, stopLoading } from "../../state/actions";

/**
 * To update Navbar title action
 * @param {string}
 */

const loginError = (err) => {
  return {
    type: loginConstants.LOGIN_FAILURE,
    LoginError: err,
  };
};

const loginSuccess = (data) => {
  return {
    type: loginConstants.LOGIN_SUCCESS,
    LoginData: data,
  };
};

const loginEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading())
    commonApi
      .login(data)
      .then((response) => {
        if(response.data.hasOwnProperty("statusCode")&&response.data.statusCode===401){
          dispatch(loginError(response.data));
          dispatch(showMessageError(response.data.statusCode));
        }else{

          dispatch(loginSuccess(response.data.info));
          dispatch(stopLoading())
          dispatch(showMessageSuccess("Fetched Successfully"))
        }
      })
      .catch((err) => {
        dispatch(loginError(err));
        dispatch(stopLoading());
        dispatch(showMessageError(err?.response));
      });
  };
};

const logoutError = (err) => {
  return {
    type: loginConstants.LOGOUT_FAILURE,
    LoginError: err,
  };
};

const logoutSuccess = (data) => {
  return {
    type: loginConstants.LOGOUT_SUCCESS,
    LoginData: data,
  };
};
const logoutEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading())
    commonApi
      .getDataRequest(data)
      .then((response) => {
        dispatch(logoutSuccess(data));
        dispatch(stopLoading())
      })
      .catch((err) => {
        dispatch(logoutError(err));
        dispatch(stopLoading());
      });
  };
};

export const appAction = {
  loginEvent,
  logoutEvent,
};
