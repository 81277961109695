import React, { useEffect, useState } from "react";
import { Col, Form, Input, Collapse, Row, Select, DatePicker } from "antd";
import { options } from "../../Common/commondata";
import { ImageUpload } from "../../Common/Components/ImageUpload";
import { ReactQuillComponentRender } from "./ReactQuillComponentRender";
import EditMagazine from "./EditMagazine";
import dayjs from "dayjs";
const { TextArea } = Input;

function EditDailyScoop({ values, form, setDescValue, subType }) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };
  const normFilePoster = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };
  const formatDateForInput = (dateString) => {
    if (!dateString) return null;
    return dayjs(dateString).format('YYYY-MM-DDTHH:mm');
  };

  useEffect(() => {
    // if (Object.keys(values).length > 0) {
    //   form.setFieldsValue({
    //     values,
    //   });
    // }
    if (Object.keys(values).length > 0) {
      const formattedValues = {
        ...values,
        scheduledActivationTime: values?.scheduledActivationTime ? dayjs(values.scheduledActivationTime) : null,
      };
      console.log(formattedValues, "formattedValues")
      form.setFieldsValue(formattedValues);
    }
  }, [values]);

  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    // Get the current date and time in the format required by datetime-local
    const now = dayjs().format('YYYY-MM-DDTHH:mm');
    setCurrentDateTime(now);
  }, []);

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Please add Title!",
          },
        ]}
      >
        <TextArea placeholder="Title" autoSize />
      </Form.Item>

      {subType === "dailyScoops" && (
        <Form.Item
          label="Tag"
          name="tag"
          rules={[
            {
              required: true,
              message: "Please add Tag!",
            },
          ]}
        >
          <Select options={options}></Select>
        </Form.Item>
      )}
      <Form.Item
        label="Redirect URL"
        name="redirectURL"
        rules={[
          {
            required: true,
            message: "Please add Redirect URL!",
          },
        ]}
      >
        <Input style={{ textAlign: "left" }} placeholder="Redirect URL" />
      </Form.Item>
      {subType !== "magazine" ? (
        <>
          <Form.Item
            label="Thumbnail"
            name="thumbnail"
            rules={[
              {
                required: true,
                message: "Please add Thumbnail!",
              },
            ]}
            getValueFromEvent={normFile}
          >
            <ImageUpload origin="thumbnail" maxCount={1} />
          </Form.Item>
          {subType === "specialProfile" && (
            <Form.Item
              label="Poster"
              name="poster"
              getValueFromEvent={normFilePoster}
            >
              <ImageUpload origin="poster" maxCount={1} />
            </Form.Item>
          )}
          <Form.Item
            label="Key words"
            name="keywords"
            rules={[
              {
                required: true,
                message: "Please add Keywords!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      ) : (
        <EditMagazine
          Form={Form}
          form={form}
          normFile={normFile}
          normFilePoster={normFilePoster}
        />
      )}
      <Form.Item label="Upload time" name="scheduledActivationTime">
        {/* <Input type="datetime-local" min={currentDateTime} /> */}
        <DatePicker showTime  format="YYYY-MM-DD HH:mm:ss"/>
      </Form.Item>

      <Form.Item
        label="Meta Description"
        name="metaDescription"
      >
        <TextArea placeholder="Meta Description" autoSize />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
      // rules={[
      //   {
      //     required: true,
      //     message: "Please add description!",
      //   },
      // ]}
      >
        <ReactQuillComponentRender setDescValue={setDescValue} />
      </Form.Item>
    </Form>
  );
}
export { EditDailyScoop };
