import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Result
      // status="404"
      // title="404"
      subTitle="No data"
      extra={
        <Button
          type="primary"
          style={{ width: "fit-content" }}
          onClick={() => navigate(-1)}
        >
          Back Home
        </Button>
      }
    />
  );
};

export { NotFound };
