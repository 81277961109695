import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row, Tabs, Typography, Alert } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { clearNewEntry, dashboardAction } from "../Dashboard/state/actions";
import Spinner from "../Spinner/Spinner";
import { useMediaQuery } from "react-responsive";
import { EditDailyScoop } from "./Components/EditDailyScoop";
import { EditUptrending } from "./Components/EditUptrending";
import { EditMagazine } from "./Components/EditMagazine";
import { EditSpecialProfile } from "./Components/EditSpecialProfile";
import { ErrorHandling } from "../ErrorHandling/ErrorHandling";
import dayjs from "dayjs";

const { Title } = Typography;

const AddContent = () => {
  const [form] = Form.useForm();
  const { subType, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery({ maxWidth: 768 });
  const { newEntry } = useSelector((state) => state.dashboardReducer);
  const [descvalue, setDescValue] = useState(newEntry?.description);
  useEffect(() => {
    if (id) {
      dispatch(
        dashboardAction.getContentById(
          id,
          subType === "upTrending"
            ? "upTrending"
            : subType === "magazine"
              ? "magazine"
              : subType === "specialProfile"
                ? "specialProfile"
                : "dailyScoops",
          "admin"
        )
      );
    }
    // eslint-disable-next-line
  }, [subType]);

  useEffect(() => {
    if (newEntry) {
      setDescValue(newEntry?.description);
    }
    // eslint-disable-next-line
  }, [newEntry]);

  useEffect(() => {
    if (id === undefined) {
      form.resetFields();
      setDescValue("<div><br></div>");
    }
    // eslint-disable-next-line
  }, [id]);

  const handleFinishtest = (e) => {
    let event = { ...e, description: descvalue }
    const { scheduledActivationTime, ...rest } = event;
    let timestamp;
    let data;
    if (scheduledActivationTime) {
      timestamp = dayjs(event.scheduledActivationTime).valueOf();
      data = { ...rest, scheduledActivationTime: timestamp };
    } else {
      data = { ...rest };
    }
    setDescValue("<div><br></div>");
    dispatch(clearNewEntry());
    let key =
      subType === "upTrending"
        ? "upTrending"
        : subType === "magazine"
          ? "magazine"
          : subType === "specialProfile"
            ? "specialProfile"
            : "dailyScoops";
    const changedFields = {};
    Object.keys(data).forEach((field) => {
      if (!newEntry.hasOwnProperty(field) || data[field] !== newEntry[field]) {
        // Add the field to the changedFields object if the values are different or it's a new field
        changedFields[field] = data[field];
      }
    });

    switch (key) {
      case "dailyScoops":
      case "upTrending": {
        if (id && id !== "undefined") {
          dispatch(
            dashboardAction.dashboardUpdateContentEvent(
              changedFields,
              id,
              key,
              1,
              "daily"
            )
          );
          if (key === "dailyScoops") {
            setTimeout(() => {
              navigate(`/dashboard/${data?.tag}`);
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(`/dashboard/${key}`);
            }, 1000);
          }
        } else {
          dispatch(dashboardAction.dashboardAddContentEvent(data, key));
          if (key === "dailyScoops") {
            setTimeout(() => {
              navigate(`/dashboard/${data?.tag}`);
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(`/dashboard/${key}`);
            }, 1000);
          }
        }
        break;
      }
      case "specialProfile": {
        if (id && id !== "undefined") {
          dispatch(
            dashboardAction.dashboardUpdateContentEvent(
              changedFields,
              id,
              key,
              1,
              "daily"
            )
          );
          if (key === "dailyScoops") {
            setTimeout(() => {
              navigate(`/dashboard/${data?.tag}`);
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(`/dashboard/${key}`);
            }, 1000);
          }
        } else {
          dispatch(dashboardAction.dashboardAddContentEvent(data, key));
          if (key === "dailyScoops") {
            setTimeout(() => {
              navigate(`/dashboard/${data?.tag}`);
            }, 1000);
          } else {
            setTimeout(() => {
              navigate(`/dashboard/${key}`);
            }, 1000);
          }
        }
        break;
      }
      case "magazine": {
        const formData = new FormData();

        Object.entries(changedFields).forEach(([field, value]) => {
          // Append each changed field to the formData object
          formData.append(field, value);
        });
        // Append insights files separately if they exist
        if (changedFields.hasOwnProperty("insights")) {
          changedFields["insights"].forEach((file) => {
            formData.append("insights", file);
          });
        }

        if (id) {
          dispatch(
            dashboardAction.dashboardUpdateContentEvent(
              formData,
              id,
              key,
              1,
              "daily"
            )
          );
        } else {
          data["insights"]?.forEach((file) => {
            formData.append("insights", file);
          });
          formData.append("title", data?.title);
          formData.append("description", data?.description);
          formData.append("pdfURL", data?.pdfURL);
          formData.append("poster", data?.poster);
          formData.append("thumbnail", data?.thumbnail);
          formData.append("authorName", data?.authorName);
          formData.append("publishingYear", data?.publishingYear);
          formData.append("keywords", data?.keywords);
          formData.append("redirectURL", data?.redirectURL);
          dispatch(dashboardAction.dashboardAddContentEvent(formData, key));
        }
        setTimeout(() => {
          navigate(`/dashboard/${key}`);
        }, 1000);
        break;
      }

      default:
        break;
    }
    form.resetFields();
  };
  const tabData = [
    {
      label: "Daily Scoops",
      key: "dailyScoops",
      // disabled: subType === "dailyScoops" ? false : true,
      disabled: id === undefined ? false : true,
      children: (
        <>
          <EditDailyScoop
            subType={
              subType === "upTrending"
                ? "upTrending"
                : subType === "magazine"
                  ? "magazine"
                  : subType === "specialProfile"
                    ? subType
                    : "dailyScoops"
            }
            form={form}
            values={Array.isArray(newEntry) ? {} : newEntry}
            setDescValue={setDescValue}
          />
        </>
      ),
    },
    {
      label: "Up Trending",
      key: "upTrending",
      disabled: id === undefined ? false : true,

      children: (
        <>
          <EditDailyScoop
            subType={
              subType === "upTrending"
                ? "upTrending"
                : subType === "magazine"
                  ? "magazine"
                  : subType === "specialProfile"
                    ? subType
                    : "dailyScoops"
            }
            form={form}
            values={Array.isArray(newEntry) ? {} : newEntry}
            setDescValue={setDescValue}
          />
        </>
      ),
    },
    {
      label: "Special Profile",
      key: "specialProfile",
      disabled: id === undefined ? false : true,

      // disabled: subType === "upTrending" ? false : true,
      children: (
        <>
          <EditDailyScoop
            subType={
              subType === "upTrending"
                ? "upTrending"
                : subType === "magazine"
                  ? "magazine"
                  : subType === "specialProfile"
                    ? subType
                    : "dailyScoops"
            }
            form={form}
            values={Array.isArray(newEntry) ? {} : newEntry}
            setDescValue={setDescValue}
          />
        </>
      ),
    },
    {
      label: "Magazines",
      key: "magazine",
      disabled: id === undefined ? false : true,

      // disabled: subType === "magazine" ? false : true,
      children: (
        <>
          <EditDailyScoop
            subType={
              subType === "upTrending"
                ? "upTrending"
                : subType === "magazine"
                  ? "magazine"
                  : subType === "specialProfile"
                    ? subType
                    : "dailyScoops"
            }
            form={form}
            values={Array.isArray(newEntry) ? {} : newEntry}
            setDescValue={setDescValue}
          />
        </>
      ),
    },
  ];

  const onChangeTag = (tagValue) => {
    form.resetFields();
    navigate(`/dashboard/addContent/${tagValue}`);
  };

  const { isLoading, message } = useSelector((str) => str.loadingReducer);

  if (isLoading) {
    return <Spinner />;
  }
  if (typeof message === "object") {
    return <ErrorHandling />;
  }

  return (
    // <Form form={form} onFinish={() => handleFinish(subType)}>

    <Row gutter={[12, 12]} justify={"center"} style={{ background: "#f8f8f8" }}>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={10}
        style={{
          paddingLeft: "2rem",
          paddingRight: "2rem",
        }}
      >
        {/* <Form.Item name={"tabs"}> */}
        <Tabs
          activeKey={
            subType === "upTrending"
              ? "upTrending"
              : subType === "magazine"
                ? "magazine"
                : subType === "specialProfile"
                  ? "specialProfile"
                  : "dailyScoops"
          }
          size={isMediumScreen ? "middle" : "large"}
          items={tabData}
          onChange={(e) => onChangeTag(e)}
        />
        {/* </Form.Item> */}
      </Col>
      <Col xs={22} sm={22} md={11} lg={11} xl={13}>
        <Row style={{ alignItems: "center" }}>
          <Col span={18}>
            <Title level={3} style={{ textDecoration: "underline" }}>
              Preview
            </Title>
          </Col>
          <Col span={6}>
            <Form form={form} onFinish={(e) => handleFinishtest(e)}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Card style={{ height: "100vh" }}>
          {/* {values.description !== "<div><br></div>" ? ( */}
          <div dangerouslySetInnerHTML={{ __html: descvalue }} />
          {/* ) : (
            // <div>Add Conntent to preview...</div>
          // )}  */}
        </Card>
      </Col>
    </Row>
  );
};
export default AddContent;
