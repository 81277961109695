import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// get data by id, tag ==> source
const getDataRequest = (path, source) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/${path}?page=${source}&role=user`, config);
};

// get magazine data
const getMagazineDataRequest = (path) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/${path}`, config);
};

// standered get api call
const getApiRequest = async (path) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/${path}`, config);
};

// put data
const putDataRequest = (data) => {
  let accessTokan = "Test";
  return axios.put(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const womenLeadersAndCoverStoriesApi = {
  getApiRequest,
  getDataRequest,
  putDataRequest,
  getMagazineDataRequest,
};
