import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { Helmet } from "react-helmet-async";
import { SubscribeSection } from "../../Common";
import { ErrorHandling } from "../../ErrorHandling/ErrorHandling";
import DailyScoop from "../../Home/Components/DailyScoop";
import styles from "../Styles/dailyScoopDescription.module.css";
import { SocialShare } from "../../Common/Components/SocialShare";
const { Title } = Typography;

const DailyScoopDescription = () => {
  const params = useParams();

  // useEffect(() => {
  //   // dispatch(dailyScoopAction.getDailyScoopByIDEvent("user", params.id));
  //   dispatch(homeAction.getHomeModalData());
  //   dispatch(dailyScoopAction.getAllDailyScoopEvent("user", 1));
  // }, [dispatch, params.id]);

  // useEffect(() => {
  //   dispatch(dailyScoopAction.getAllDailyScoopEvent("user", 1));
  // }, []);

  // const { newEntryDescription } = useSelector(
  //   (state) => state.dailyScoopReducer
  // );
  const { article } = useSelector((str) => str.commonReducer);
  const { newDailyScoopEntries } = useSelector((str) => str.dailyScoopReducer);
  const { message, messageID } = useSelector((state) => state.loadingReducer);

  //before remove or change this section contact Mahesh
  // useEffect(() => {
  //   dispatch(homeAction.getHomeModalData());
  // }, [dispatch]);

  const { homeData } = useSelector((state) => state.homeReducer);
  // if (isLoading) {
  //   return <Spinner />;
  // }

  if (typeof message === "object") {
    return (
      <ErrorHandling code={message?.status} statusText={message?.statusText} />
    );
  }
  if (typeof messageID === "object") {
    return (
      <ErrorHandling
        code={messageID?.status}
        statusText={messageID?.statusText}
      />
    );
  }

  let getConten = (data) => {
    if (data?.description) {
      const doc = new DOMParser().parseFromString(
        data.description,
        "text/html"
      );

      const divs = doc.querySelectorAll("div");
      let extractedTexts = [];

      for (let i = 0; i < divs.length; i++) {
        const hasImg = divs[i].querySelector("img");
        if (!hasImg) {
          extractedTexts.push(divs[i].textContent.trim());
        }
      }
      let combinedText = extractedTexts.slice(0, 3).join(" ");
      return combinedText;
    }
    return "Thanks";
  };
  return (
    <>
      <Helmet>
        <meta name="keywords" content={article?.keywords}></meta>
        <title>{article?.title}</title>
        <meta name="description" content={article?.metaDescription ? article?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />

        <meta name="twitter:card" content={"type"} />
        <meta name="twitter:title" content={article?.title} />
        <meta name="twitter:description" content={article?.metaDescription ? article?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />

        <meta property="og:image" content={article?.thumbnail} />
        <meta property="og:description" content={article?.metaDescription ? article?.metaDescription : "Visionary Vogue: Where Business Transformation Takes Center Stage"} />
        <meta property="og:title" content={article?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`https://visionaryvogues.com/${article?.redirectURL}`}
        />
      </Helmet>
      <div className={styles.dailyScoopTitleWrapper}>
        <Link to={`/daily-scoop/${article?.tag}`}>
          <Title
            style={{ display: "block", marginTop: "20px" }}
            className={styles.dailyScoopTitle}
          >
            {article?.tag === "sportsAndFitness" ? 'Sports and fitness' : article?.tag}
          </Title>
        </Link>
      </div>
      <Row
        gutter={[0, 16]}
        style={{
          justifyContent: "center",
          marginTop: "50px",
          marginBottom: "20px",
          overflowx: "hidden",
        }}
      >
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={15}
          xl={17}
          className={styles.descContent}
        >
          <SocialShare
            url={`https://visionaryvogues.com/${article?.redirectURL}`}
            content={getConten(article)}
          />

          <div
            className={styles.descImg}
            dangerouslySetInnerHTML={{
              __html: article?.description,
            }}
          />
        </Col>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={8}
          xl={6}
          style={{ position: "sticky", top: 0, height: "max-content" }}
        >
          <SubscribeSection newEntry={newDailyScoopEntries} />
        </Col>
      </Row>
      <DailyScoop
        dailyScoops={homeData?.onCover}
        related={"On The Cover"}
        alignment="poster"
      />
    </>
  );
};

export default DailyScoopDescription;
