import {
  EnvironmentFilled,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailFilled,
  YoutubeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { FaPhoneVolume, FaXTwitter } from "react-icons/fa6";
import styles from "./styles/Form.module.css";
import { useDispatch } from "react-redux";
import { contactUsAction } from "./state/actions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { constants } from "../Common/Components/Constants";

const { Text, Title } = Typography;
const { TextArea } = Input;

const ContactForm = () => {
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const [values, setValues] = useState({
    name: "",
    email: "",
    city: "",
    message: "",
  });

  const handleSubmit = (e, source) => {
    e.preventDefault();
    dispatch(contactUsAction.AddContactUsModalData(values, source));

    setValues({
      name: "",
      email: "",
      city: "",
      message: "",
    });

    messageApi.open({
      type: "success",
      content: "Contact received successfully",
    });
  };
  return (
    <>
      {contextHolder}
      <div className={styles.textStyle}>
        <Helmet>
          <meta name="keywords" content={constants.contact.title}></meta>
          <title>{constants.contact.title}</title>
          <meta name="description" content={constants.contact.description} />

          <meta property="og:title" content={constants.contact.title} />
          <meta property="og:type" content="website" />
          <meta
            property="og:URL"
            content={`https://visionaryvogues.com/contactUs`}
          />
        </Helmet>
        <Row gutter={[16, 16]} style={{ justifyContent: "center" }}>
          <Col xs={21} sm={20} md={20} lg={10} xl={10}>
            <Text className={styles.hellotitle}>
              Hello! We are Visionary Vogues.
            </Text>
            <Title className={styles.contactUsTitle}>Contact Us</Title>
            <Text className={styles.normaltext}>
              Feel free to use the contact form to the right to reach out to us,
              Our Team will contact you soon...
            </Text>
            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              <Col xs={24} sm={24} md={13} lg={24} xl={11}>
                <Title className={styles.smallTitle}>Reach us through</Title>
                <div className={styles.icontext}>
                  <MailFilled />
                  <Text className={styles.text} style={{ fontSize: "1rem" }}>
                    <a
                      href="mailto:info@visionaryvogues.com"
                      style={{ color: "white" }}
                      data-ajax="false"
                    >
                      &nbsp;&nbsp;info@visionaryvogues.com
                    </a>
                  </Text>
                </div>
                <div className={styles.icontext}>
                  <FaPhoneVolume />
                  <Text className={styles.text}>
                    <a href="tel:+13479832711" style={{ color: "white" }}>
                      &nbsp;&nbsp;+1 347 983 2711
                    </a>
                  </Text>
                </div>
                <div className={styles.icontext}>
                  <EnvironmentFilled />
                  <Text className={styles.text}>
                    &nbsp;&nbsp;77 Water St, Brooklyn, New York, NY 11201,
                    United States.
                  </Text>
                </div>
              </Col>
              <Col xs={0} sm={0} md={1} lg={0} xl={2}>
                <Divider
                  type="vertical"
                  style={{ height: "100%", background: "white" }}
                />
              </Col>
              <Col xs={24} sm={24} md={10} lg={24} xl={11}>
                <Title className={styles.smallTitle}>Social networks</Title>
                <Space direction="horizontal">
                  <Link to="https://youtube.com/@VisionaryVogues?si=LlKhzYpHm29Ltfjw">
                    <YoutubeOutlined
                      style={{
                        fontSize: "30px",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <Link to="https://www.instagram.com/visionary_vogues/">
                    <InstagramOutlined
                      style={{
                        fontSize: "30px",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <Link to="https://www.facebook.com/profile.php?id=61559729691227">
                    <FacebookOutlined
                      style={{
                        fontSize: "25px",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <Link to="https://www.linkedin.com/company/102499826/admin/feed/posts/">
                    <LinkedinOutlined
                      style={{
                        fontSize: "25px",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <Link to="https://twitter.com/home?lang=en">
                    <FaXTwitter
                      style={{
                        fontSize: "24px",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={1} xl={1}>
            <Divider
              style={{ background: "white", height: "100%", padding: 0 }}
              type="vertical"
            />
          </Col>
          <Col xs={21} sm={20} md={20} lg={9} xl={9}>
            <Title className={styles.messageTitle}>Send a message</Title>
            <Form layout="vertical" style={{ textAlign: "left" }}>
              <Form.Item>
                <Input
                  className={styles.formInput}
                  placeholder="Your name"
                  type="text"
                  name="name"
                  onChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                  value={values.name}
                />
              </Form.Item>
              <Form.Item
                required
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  name="email"
                  placeholder="@email.com"
                  className={styles.formInput}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  value={values.email}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="Your city"
                  name="city"
                  value={values.city}
                  onChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                  className={styles.formInput}
                />
              </Form.Item>
              <Form.Item>
                <TextArea
                  rows={4}
                  className={styles.formInput}
                  placeholder="Message"
                  name="message"
                  onChange={(e) =>
                    setValues({ ...values, message: e.target.value })
                  }
                  value={values.message}
                />
              </Form.Item>
              <div style={{ textAlign: "center", paddingTop: "10px" }}>
                <Button
                  className={styles.sendBtn}
                  onClick={(e) => handleSubmit(e, "contact")}
                >
                  Send message
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export { ContactForm };
