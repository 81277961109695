import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// get data by id, tag ==> source
const getDataRequest = async (path, source, role) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let url = `${apiURL}/${path}/${source}`;
  if (role !== undefined) {
    url += `?role=${role}`;
  }
  return axios.get(url, config);
};

// get magazine data
const getMagazineDataRequest = (path) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(`${apiURL}/${path}`, config);
};

// standered get api call
const getApiRequest = async (path, role, page) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.get(`${apiURL}/${path}?role=${role}&page=${page}`, config);
};

// put data
const putDataRequest = (data) => {
  let accessTokan = "Test";
  return axios.put(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const magazineApi = {
  getApiRequest,
  getDataRequest,
  putDataRequest,
  getMagazineDataRequest,
};
